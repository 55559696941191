
import SubBanner from "@/common/subbanner";
import TitleAnimation from "@/common/titleanimation";
import { AnimatedCursorContext } from "@/cursor/animatedcursormanager";
import ProcessCard from "@/section/processcard";
import ServicePageCard from "@/section/servicespagecard";
import { motion, useInView} from "framer-motion";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useRef, React, useEffect, useContext, useState } from "react";

export function Services() {
  const containerRef = useRef();
  useEffect(() => {
    const mask = document.querySelector('.maskimg');

    const onMouseMove = (pos) => {
      let x = parseInt(pos.clientX / window.innerWidth * 100);
      let y = parseInt(pos.clientY / window.innerHeight * 100);

      mask.style.setProperty('--mouse-x', x + '%');
      mask.style.setProperty('--mouse-y', y + '%');
    };

    document.addEventListener('pointermove', onMouseMove);

    return () => {
      document.removeEventListener('pointermove', onMouseMove);
    };
  }, []);
  const { cursorStyleHandler } = useContext(AnimatedCursorContext);
  const imgEnter = () => {
    cursorStyleHandler("none");
  };

  const imgLeave = () => {
    cursorStyleHandler("default");
  };

  const { ref } = useInView({
    triggerOnce: true,
    threshold: 1,
  });

  const services = [
    {
      title: 'Web design',
      details: 'Personalize Your Website Look',
      items: [
        'User interface',
        'User experience',
        'Websites',
        'Visual design',
        'Prototypes',
        'Web & Mobile apps',
        'SaaS product design',
        'Design systems',
        'Interaction design',]
    },
    {
      title: 'Web Application Development',
      details: 'Customize Your Web Development Plan',
      items: [
        'React JS',
        'Next JS',
        'Node JS',
        'Angular JS',
        'WordPress',
        'Magento',
        'Shopify',
        'Laravel',
        'PHP',
        'HTML5 & CSS3',
        'Less & Sass',
        'Bootstrap',
        'Tailwind',
        'Javascript',
        'jQuery',
      ]
    },
    {
      title: 'Server Setup & Management',
      details: 'We can Manage and Handle your server.',
      items: [
        'Visual identity',
        'Branding strategy',
        'Mockups',
        'Graphic designs',
        'Style guide',
        'Typography',
      ]
    },
  ];

  const processCards = [
    {
      title: 'Analysis and Strategy Development',
      description: ['Project Management', 'Defining Expectations', 'Market Analysis', 'Online Strategy', 'User Experience Research (UX)']
    },
    {
      title: 'Innovation and User Experience Design',
      description: ['Concept Boards', 'UI Design', 'UX Design', 'Interactive Design', 'Design Framework']
    },
    {
      title: 'Technological Advancement and WEB and APP Development',
      description: ['Website Development', 'Front-end Engineering', 'Tailored CMS Integrations', 'E-commerce Solutions', 'SAAS Deployment']
    },
    {
      title: 'Quality Assurance and Deployment',
      description: ['writing test cases', 'Unit testing', 'Integration testing', 'System testing', 'Performance testing', 'Security testing', 'Cross-browser testing',]
    },
    {
      title: 'Assessment and Iteration',
      description: ['Requirement Analysis', 'Planning the test', 'Developing the test case', 'Setting up the test environment', 'Execution of the test', 'Closing the test cycle']
    }
  ];
  const [currentURL, setCurrentURL] = useState(window.location.href);
  useEffect(() => {
    setCurrentURL(window.location.href);
  }, []);
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Our Services</title>
          <meta name="title" content="Our Services - Web Crown" />
          <meta name="og:url" content={currentURL} />
          <meta name="og:image" content="./img/logo.svg" />
        </Helmet>
      </HelmetProvider>
      <motion.div
        className="h-full relative z-0"
        initial={{ y: "-200vh" }}
        animate={{ y: "0%" }}
        transition={{ duration: 1 }}
      >
        <div className="text-center">
          <SubBanner
            title="Responsive Development Partner"
            content="At Web Crown, we are pioneers in the realm of IT solutions, committed to reshaping global business standards. By merging business insight with technical proficiency, we offer unmatched value through inventive approaches and cost-effective methodologies. Our dedication lies in empowering enterprises and transforming livelihoods, aspiring to create a favorable influence on the world, one client's journey at a time."
          />
          <section className="sec-padding px-0 !py-0 relative">
            <div className="mx-auto relative h-full">
              <motion.div className="absolute inset-0 flex items-center container z-10">
                <p className="text-[5vw] sm:text-[5vw] md:text-[4vw] text-center ">
               <span className="text-red-500">WEBCROWN®</span> has proudly empowered to companies navigating the corporate landscape.
                </p>
              </motion.div>

              <div className="absolute left-0 right-0">
                <div className="opacity-20">
                  <img src="./img/services_mask_img.webp" alt=""   className="w-full" />
                </div>
              </div>
              <div className="maskimg" onMouseEnter={imgEnter} onMouseLeave={imgLeave}>
                <div className="">
                  <img src="./img/services_mask_img_hover.webp" alt=""   className="w-full" />
                </div>
              </div>
            </div>
          </section>
          <section className="sec-padding px-0 !pb-0">
            <div className="container mx-auto">
              <div className="relative w-fit text-center mx-auto mb-10 lg:mb-24">
                <TitleAnimation text1="Our" text2="Services" />
              </div>
              <div className="relative w-fit text-center mx-auto mb-20 lg:mb-24">
                <p className="common-fonts max-w-5xl">Our mission is to elevate tech businesses within the digital sphere, fostering meaningful impact through our offerings in design, branding, and development services.</p>
              </div>

              <div className="relative h-full">
                {services.map((service, index) => (
                  <ServicePageCard
                    key={index}
                    title={service.title}
                    details={service.details}
                    items={service.items} />
                ))}
              </div>
            </div>
          </section>
          <section className="sec-padding px-0 !pb-0">
            <div className="relative w-fit text-center mx-auto mb-24">
              <TitleAnimation text1="Our" text2="Process" />
            </div>
            <div className="container mx-auto">
              <div className="our-process">
                {processCards.map((card, index) => (
                  <ProcessCard
                    key={index}
                    index={index}
                    title={card.title}
                    description={card.description}
                    refProp={useRef(null)}
                  />
                ))}
              </div>
            </div>
          </section>
        </div>
      </motion.div>
    </>
  )
}
export default Services;

