import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import Lenis from '@studio-freight/lenis';

// Register GSAP plugins
gsap.registerPlugin(ScrollTrigger);

const ScrollingGallery = () => {
  const aboutGallery = [
    { src: "./img/digital/01.webp", alt: "Our Digital" },
    { src: "./img/digital/02.webp", alt: "Our Digital" },
    { src: "./img/digital/03.webp", alt: "Our Digital" },
    { src: "./img/digital/04.webp", alt: "Our Digital" },
    { src: "./img/digital/05.webp", alt: "Our Digital" },
    { src: "./img/digital/06.webp", alt: "Our Digital" },
  ];
  const aboutGallery2 = [
   
    { src: "./img/digital/07.webp", alt: "Our Digital" },
    { src: "./img/digital/08.webp", alt: "Our Digital" },
    { src: "./img/digital/09.webp", alt: "Our Digital" },
    { src: "./img/digital/10.webp", alt: "Our Digital" },
    { src: "./img/digital/11.webp", alt: "Our Digital" },
    { src: "./img/digital/12.webp", alt: "Our Digital" },
    
  ];
  const aboutGallery3 = [
    { src: "./img/digital/13.webp", alt: "Our Digital" },
    { src: "./img/digital/14.webp", alt: "Our Digital" },
    { src: "./img/digital/15.webp", alt: "Our Digital" },
    { src: "./img/digital/16.webp", alt: "Our Digital" },
    { src: "./img/digital/17.webp", alt: "Our Digital" },
    { src: "./img/digital/18.webp", alt: "Our Digital" },
    
  ];

  const scrollingImgRef = useRef();
  const scrollingImgRef02 = useRef();
  const scrollingImgRef03 = useRef();

  useEffect(() => {
    let direction = 1; // 1 = forward, -1 = backward scroll

    const roll = (targets, vars, reverse) => {
      vars = vars || {};
      vars.ease || (vars.ease = "none");
      const tl = gsap.timeline({
        repeat: -1,
        onReverseComplete() {
          this.totalTime(this.rawTime() + this.duration() * 30); // otherwise when the playhead gets back to the beginning, it'd stop. So push the playhead forward 10 iterations (it could be any number)
        }
      }),
        elements = gsap.utils.toArray(targets),
        clones = elements.map(el => {
          let clone = el.cloneNode(true);
          el.parentNode.appendChild(clone);
          return clone;
        }),
        positionClones = () => elements.forEach((el, i) => gsap.set(clones[i], { position: "absolute", overwrite: false, top: el.offsetTop, left: el.offsetLeft + (reverse ? -el.offsetWidth : el.offsetWidth) }));
      positionClones();
      elements.forEach((el, i) => tl.to([el, clones[i]], { xPercent: reverse ? 100 : -100, ...vars }, 0));
      window.addEventListener("resize", () => {
        let time = tl.totalTime(); // record the current time
        tl.totalTime(0); // rewind and clear out the timeline
        positionClones(); // reposition
        tl.totalTime(time); // jump back to the proper time
      });
      return tl;
    };

    const roll1 = roll(scrollingImgRef.current.children, { duration: 50 });
    const roll2 = roll(scrollingImgRef02.current.children, { duration: 50 }, true);
    const roll3 = roll(scrollingImgRef03.current.children, { duration: 50 });

    const lenis = new Lenis();

    lenis.on('scroll', (e) => {
      ScrollTrigger.update();
    });

    ScrollTrigger.create({
      onUpdate(self) {
        if (self.direction !== direction) {
          direction *= -1;
          gsap.to([roll1, roll2, roll3], { timeScale: direction, overwrite: true });
        }
      }
    });

    // Update Lenis on scroll
    lenis.on('scroll', (e) => {
      ScrollTrigger.update();
    });

    // Clean up on unmount
    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
      lenis.destroy();
    };
  }, []);

  return (
    <div>
      <div className="scrolling-img" ref={scrollingImgRef}>
        <div className="gallery-img">
          {aboutGallery.map((member, index) => (
            <img key={index} src={member.src} alt={member.alt} />
          ))}
        </div>
      </div>
      <div className="scrolling-img" ref={scrollingImgRef02}>
        <div className="gallery-img">
          {aboutGallery2.map((member, index) => (
            <img key={index} src={member.src} alt={member.alt} />
          ))}
        </div>
      </div>
      <div className="scrolling-img" ref={scrollingImgRef03}>
        <div className="gallery-img">
          {aboutGallery3.map((member, index) => (
            <img key={index} src={member.src} alt={member.alt} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ScrollingGallery;
