import React, { useContext, useEffect} from "react";
import { AnimatedCursorContext } from "@/cursor";
function FooterBg() {
  useEffect(() => {
    const mask = document.querySelector('.mask');

    const manageMouseMove = (pos) => {
      let x = parseInt(pos.clientX / window.innerWidth * 100);
      let y = parseInt(pos.clientY / window.innerHeight * 50);

      mask.style.setProperty('--mouse-x', x + '%');
      mask.style.setProperty('--mouse-y', y + '%');
    };

    document.addEventListener('pointermove', manageMouseMove);

    return () => {
      document.removeEventListener('pointermove', manageMouseMove);
    };
  }, []);
  const { cursorStyleHandler } = useContext(AnimatedCursorContext);

    const textEnter = () => {
      cursorStyleHandler("none");
    };
  
    const textLeave = () => {
      cursorStyleHandler("default");
    };

  return (
    <div className="px-0 !pb-0 relative">
      <div className="absolute left-0 right-0">
        <div className="webcrown-bg opacity-10">
          Web crown
        </div>
      </div>
      <div className="mask" onMouseEnter={textEnter} onMouseLeave={textLeave}>
        <div className="webcrown-bg">
          Web crown
        </div>
      </div>
      
    </div >
  )
}
export default FooterBg
