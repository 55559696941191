import { motion, useScroll, useTransform } from 'framer-motion';
import React, { useRef } from 'react';


export default function Paragraph({paragraph}) {

  const parcontain = useRef(null);
  const { scrollYProgress } = useScroll({
    target: parcontain,
    offset: ["start 0.9", "start 0.25"]
  })

  const opacity = useTransform(scrollYProgress, [0, 1], [0, 1])

  return (
    <motion.span 
      ref={parcontain}         
      className='paragraph'
      style={{opacity}}
    >
      {paragraph}
    </motion.span>
  )
}