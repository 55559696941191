import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "../public/css/tailwind.scss";
import { ReactLenis } from "@studio-freight/react-lenis";

ReactDOM.createRoot(document.getElementById("root")).render(

  <React.StrictMode>
    <BrowserRouter>
     
        <ReactLenis className="relative z-1" root options={{
          lerp: 0.06,
          duration: 10,
          smoothTouch: true
        }}>
          <App />
        </ReactLenis>
      
    </BrowserRouter>
  </React.StrictMode>

);

