import { Link } from "react-router-dom";
import React, { useState, useRef } from "react";
import { Magnetic } from '@/common';
import { Menulist } from '@/nav';
import { Modal } from '@/nav';
import { motion } from 'framer-motion';

export default function Navbar() {
  const stickyElement = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      document.body.classList.add('hide-scrollbar');
    } else {
      document.body.classList.remove('hide-scrollbar');
    }
  };
  const closeMenu = () => {
    setIsOpen(false);
    document.body.classList.remove('hide-scrollbar');
  };


  const menuitems = [
    {
      id: 1,
      title: "Home",
      subtitle: "Greetings from Web Crown",
      src: "./img/home.webp",
      color: "#000000",
      link: "/"

    },
    {
      id: 2,
      title: "About Us",
      subtitle: "What we do",
      src: "./img/about.webp",
      color: "#000000",
      link: "/about"
    },
    {
      id: 3,
      title: "Services",
      subtitle: "What we offer",
      src: "./img/services.webp",
      color: "#000000",
      link: "/services"
    },
    {
      id: 4,
      title: "Career",
      subtitle: "Opportunities",
      src: "./img/career.webp",
      color: "#000000",
      link: "/career"
    },
    {
      id: 5,
      title: "Contact Us",
      subtitle: "Request quote",
      src: "./img/contact.webp",
      color: "#000000",
      link: "/contact"
    }
  ]
  const menuVariants = {
    open: { opacity: 1, y: 0 },
    closed: { opacity: 0, y: '-200%' },
    transition: {
      type: 'spring',
      stiffness: 50,
      damping: 20,
    }
  };
  const [modal, setModal] = useState({ active: false, index: 0 })
  return (
    <>
      <header>
        <div className="flex justify-between items-center w-full h-20 px-4 nav z-10">

          <div className="relative z-10">
            <Link
              className="link-underline link-underline-black"
              to="/"

              rel="noreferrer"
            >
              <svg className="w-32 sm:w-44" viewBox="0 0 180 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_468_2306)">
                  <path d="M38.1045 10.324C36.3716 14.5774 37.3247 12.2187 35.5244 16.6268C35.1104 15.8535 34.7542 15.2058 34.4365 14.5291C34.3788 14.4034 34.4943 14.1714 34.5617 13.9974C36.4486 9.36703 35.6303 11.3487 37.5365 6.72799C37.7098 6.31232 37.6713 6.08032 37.3054 5.79031C36.1598 4.8913 35.7843 3.34461 36.3138 2.02992C36.8433 0.715238 38.143 -0.0967742 39.5293 0.00956078C41.0119 0.135229 42.1383 1.12124 42.456 2.57127C42.7544 3.93429 42.1094 5.47131 40.8675 6.12865C40.415 6.37032 40.3091 6.65066 40.3091 7.12433C40.3284 13.5721 40.3187 20.0199 40.3187 26.458C40.3187 27.1443 40.3187 27.1443 39.6641 27.1443C38.2489 27.1443 38.2489 27.1443 38.2489 25.7233C38.2489 20.8029 38.2489 15.8921 38.2489 10.9717C38.2489 10.7687 38.2489 10.5657 38.2489 10.3627C38.2008 10.353 38.1526 10.3434 38.1045 10.324ZM39.2501 2.01059C38.557 2.02026 37.989 2.6196 37.9986 3.31561C38.0082 4.03095 38.5666 4.59163 39.279 4.59163C39.9722 4.59163 40.5498 3.99229 40.5305 3.29628C40.5113 2.58093 39.924 1.99126 39.2501 2.01059Z" className="fill-white" />
                  <path d="M40.338 10.3723C40.338 10.5753 40.338 10.7783 40.338 10.9813C40.338 15.9017 40.338 20.8125 40.338 25.7329C40.338 27.1539 40.338 27.1539 38.9228 27.1539C38.2682 27.1539 38.2682 27.1539 38.2682 26.4676C38.2682 20.0198 38.2585 13.572 38.2778 7.13394C38.2778 6.6506 38.1719 6.37993 37.7194 6.13826C36.4775 5.48092 35.8228 3.95356 36.1309 2.58088C36.4486 1.13085 37.575 0.14484 39.048 0.0191715C40.4343 -0.0968303 41.734 0.724849 42.2634 2.03953C42.8026 3.36389 42.4175 4.91058 41.2718 5.79992C40.906 6.08993 40.8675 6.32193 41.0408 6.7376C42.947 11.3583 42.1961 9.38631 44.083 14.007C44.1504 14.181 44.2659 14.4034 44.2081 14.5387C43.8904 15.2057 43.5342 15.8534 43.1203 16.6364C41.32 12.238 42.2153 14.5871 40.4824 10.3337C40.4343 10.3433 40.3861 10.353 40.338 10.3723ZM38.0564 3.28655C38.0371 3.98256 38.6147 4.58191 39.3079 4.58191C40.0107 4.58191 40.5787 4.0309 40.5883 3.30589C40.5979 2.60988 40.0299 2.01053 39.3368 2.00087C38.6532 1.9912 38.0756 2.58088 38.0564 3.28655Z" className="fill-white" />
                  <path d="M37.7001 58.0202C30.3738 58.0202 24.9537 58.0298 17.6274 58.0105C17.1653 58.0105 16.915 58.1168 16.6743 58.5615C15.8367 60.0792 14.1038 60.6882 12.6405 60.0405C11.1098 59.3735 10.3203 57.6818 10.7824 56.0675C11.2445 54.4628 12.8042 53.4671 14.4793 53.7571C15.596 53.9504 16.3662 54.6271 16.7994 55.6615C16.9439 55.9998 17.1364 56.0675 17.4541 56.0675C24.2028 56.0578 30.9418 56.0578 37.6905 56.0675M15.1147 57.0245C15.1051 56.2898 14.537 55.7001 13.8343 55.7001C13.1507 55.7001 12.5731 56.2995 12.5924 56.9955C12.6116 57.6915 13.1892 58.2618 13.8728 58.2618C14.5467 58.2522 15.1147 57.6915 15.1147 57.0245Z" fill="#FF0000" />
                  <path d="M37.1514 53.9599C36.3138 53.9599 35.4859 53.9793 34.6483 53.9406C34.475 53.9309 34.2921 53.7666 34.1477 53.6313C28.0537 47.8215 21.9693 42.0021 15.8849 36.1827C14.6719 35.0227 13.4492 33.8723 12.2458 32.693C12.0629 32.5093 11.9185 32.1999 11.9185 31.9486C11.8896 29.6479 11.9089 27.3472 11.8992 25.0368C11.8992 24.6115 12.0244 24.4762 12.448 24.4858C13.8632 24.4955 13.8632 24.4762 13.8632 25.9262C13.8632 27.6082 13.8728 29.2902 13.8536 30.9819C13.8439 31.3783 13.9787 31.6489 14.2579 31.9196C21.7671 39.0827 29.2571 46.2458 36.7663 53.4186C36.9107 53.5539 37.0937 53.6603 37.2669 53.7763C37.2381 53.8246 37.1995 53.8923 37.1514 53.9599Z" className="fill-white" />
                  <path d="M9.69459 30.286C8.69336 29.3194 7.6825 28.3527 6.68127 27.386C6.64276 27.4053 6.61388 27.4247 6.57537 27.444C6.63313 27.6277 6.6909 27.8113 6.75829 27.995C8.03871 31.4557 9.31913 34.9164 10.6188 38.3772C10.7921 38.8315 10.7054 39.0248 10.2626 39.1505C9.83899 39.2762 9.43465 39.4598 8.98217 39.6338C8.89553 39.4115 8.82814 39.2375 8.76075 39.0732C7.16263 34.7811 5.56451 30.4794 3.97602 26.1776C3.85087 25.849 3.71609 25.704 3.331 25.7136C1.84841 25.762 0.625752 24.8726 0.173273 23.4806C-0.279206 22.0886 0.192528 20.5999 1.34779 19.7685C2.47418 18.9565 4.01453 18.9662 5.14092 19.7879C6.2673 20.6192 6.76791 22.1659 6.25767 23.5193C6.07475 24.0123 6.19991 24.2539 6.53686 24.5633C7.53809 25.4816 8.52007 26.4096 9.47316 27.3667C9.6657 27.56 9.79086 27.8887 9.81011 28.1593C9.85825 28.8457 9.82937 29.532 9.82937 30.2184C9.78123 30.2474 9.7331 30.2667 9.69459 30.286ZM1.97356 22.3979C1.96394 23.1133 2.47418 23.6739 3.14808 23.6933C3.83161 23.7126 4.45738 23.1616 4.47664 22.5236C4.49589 21.7696 3.90863 21.1316 3.19622 21.1316C2.51269 21.1412 1.98319 21.6922 1.97356 22.3979Z" fill="#FF0000" />
                  <path d="M38.297 34.8875C38.9806 34.8875 39.5871 34.8875 40.2514 34.8875C40.261 35.1001 40.2899 35.3128 40.2899 35.5351C40.2899 41.4996 40.2995 36.8981 40.2802 42.8626C40.2802 43.2976 40.3861 43.5199 40.8001 43.7229C42.1864 44.3899 42.8603 45.898 42.5138 47.3866C42.1768 48.8463 40.7712 49.9387 39.2309 49.9387C37.7579 49.9387 36.4197 48.9043 36.0443 47.4833C35.6688 46.0333 36.3716 44.4479 37.7483 43.7519C38.1719 43.5392 38.297 43.3072 38.2874 42.8529C38.2682 36.9078 38.2778 41.5286 38.2778 35.5738C38.297 35.3515 38.297 35.1291 38.297 34.8875ZM39.3079 45.3083C38.5762 45.3083 37.9601 45.927 37.9601 46.652C37.9697 47.348 38.5762 47.957 39.279 47.9666C39.9914 47.986 40.6365 47.3576 40.6461 46.6423C40.6653 45.9269 40.0396 45.3083 39.3079 45.3083Z" className="fill-white" />
                  <path d="M13.8728 15.9502C13.8728 16.7912 13.8728 17.6225 13.8728 18.4635C13.8728 18.7052 13.9113 18.9565 13.8536 19.1789C13.8151 19.3239 13.6418 19.5172 13.507 19.5366C13.1508 19.5849 12.7849 19.5366 12.4287 19.5656C12.0148 19.5946 11.8992 19.4302 11.9089 19.0339C11.9281 17.0232 11.9185 15.0125 11.9185 13.0018C11.9185 12.0254 11.9185 11.0491 11.9185 10.0824C11.957 10.0631 12.0051 10.0437 12.0437 10.0244C12.1592 10.1694 12.2843 10.3144 12.3999 10.4691C15.3265 14.3455 18.2532 18.2219 21.1702 22.1079C21.3628 22.3593 21.5168 22.5043 21.8827 22.3883C23.4615 21.8759 25.2714 22.8136 25.8298 24.3893C26.4171 26.0617 25.6565 27.8693 24.0873 28.517C22.778 29.0584 21.5938 28.8167 20.5734 27.85C19.6491 26.9703 19.37 25.5493 19.8128 24.3216C19.8898 24.1186 19.8898 23.79 19.7743 23.6353C17.9066 21.1122 16.0197 18.5989 14.1328 16.0855C14.0942 16.0275 14.075 15.9598 14.0461 15.8922C13.998 15.9018 13.9402 15.9308 13.8728 15.9502ZM21.5746 25.5396C21.5746 26.255 22.1426 26.835 22.8261 26.8253C23.5193 26.8157 24.1065 26.2163 24.0873 25.5396C24.068 24.8146 23.5097 24.2443 22.8261 24.254C22.133 24.254 21.565 24.834 21.5746 25.5396Z" className="fill-white" />
                  <path d="M26.1282 16.0081C26.2919 16.2691 26.3882 16.4044 26.4748 16.5494C28.1692 19.4301 29.8636 22.3205 31.5676 25.2012C31.7505 25.5105 31.7794 25.7715 31.635 26.1099C30.7974 28.1012 29.9984 30.1119 29.1608 32.113C29.0164 32.4513 29.0356 32.7026 29.2667 33.0023C29.7577 33.621 30.2102 34.259 30.7108 34.9357C30.1909 35.3417 29.7095 35.7187 29.1704 36.144C27.6878 34.1527 26.2245 32.1903 24.7515 30.2183C25.2906 29.8413 25.8105 29.4836 26.3593 29.1066C26.754 29.6093 27.1487 30.1216 27.5916 30.6919C27.6878 30.5083 27.7648 30.3826 27.8226 30.2569C28.3714 28.9036 28.9297 27.5599 29.4592 26.1969C29.5363 26.0035 29.5651 25.7039 29.4689 25.5299C28.5158 23.8768 27.5338 22.2528 26.5518 20.6095C26.5133 20.5418 26.4459 20.4935 26.34 20.3775C26.1475 20.7545 25.9838 21.0831 25.8105 21.4408C25.2329 21.1508 24.6745 20.8801 24.068 20.5805C24.7419 19.0821 25.4062 17.6031 26.1282 16.0081Z" fill="#FF0000" />
                  <path d="M31.5387 53.9601C29.9118 53.9601 28.2847 53.9311 26.6674 53.9698C25.5025 53.9988 24.405 53.8248 23.2882 53.4864C20.0824 52.5101 16.8476 51.5821 13.6225 50.6637C13.1989 50.5381 12.939 50.3544 12.8234 49.9194C12.6983 49.4747 12.5154 49.0494 12.3325 48.566C12.9486 48.3244 13.507 48.1117 14.0461 47.9087C14.1231 47.9667 14.1713 47.986 14.1809 48.0054C14.306 48.7304 14.8355 48.9334 15.4709 49.117C20.6889 50.6251 25.8876 52.1524 31.1055 53.6798C31.2596 53.7281 31.4136 53.7861 31.5676 53.8344C31.558 53.8828 31.5484 53.9214 31.5387 53.9601Z" fill="#FF0000" />
                  <path d="M33.6664 38.8508C34.6869 40.2139 35.6592 41.5092 36.516 42.6596C36.0635 43.1912 35.6688 43.6552 35.2067 44.1869C34.1862 42.8239 33.1754 41.4802 32.1164 40.0592C32.6363 39.6532 33.1273 39.2762 33.6664 38.8508Z" className="fill-white" />
                  <path d="M40.2802 31.0206C40.2802 31.4459 40.2706 31.881 40.2802 32.3063C40.2899 32.606 40.184 32.722 39.8759 32.7316C38.2104 32.7993 38.2104 32.809 38.2104 31.1559C38.2104 30.6629 38.22 30.1603 38.2104 29.6673C38.2007 29.3773 38.3066 29.2709 38.5955 29.2709C39.0383 29.2806 39.4812 29.2806 39.924 29.2709C40.184 29.2612 40.2995 29.3579 40.2899 29.6286C40.2706 30.0926 40.2802 30.5566 40.2802 31.0206Z" className="fill-white" />
                  <path d="M10.9269 44.8056C10.5514 43.7809 10.1856 42.8142 9.839 41.8282C9.81012 41.7509 9.93528 41.5479 10.0315 41.4995C10.4359 41.3159 10.8499 41.1515 11.2735 41.0259C11.3794 40.9969 11.6104 41.1032 11.6489 41.1999C11.9955 42.0602 12.3228 42.9205 12.6309 43.8002C12.6694 43.9066 12.5828 44.1579 12.4961 44.1966C12.0051 44.4189 11.4949 44.5929 10.9269 44.8056Z" className="fill-white" />
                  <path d="M30.8841 38.4351C30.5182 37.9421 30.1909 37.5071 29.8347 37.0238C30.3546 36.6178 30.8359 36.2408 31.3751 35.8154C31.7217 36.2794 32.0586 36.7241 32.4148 37.2074C31.8949 37.6231 31.4232 38.0001 30.8841 38.4351Z" className="fill-white" />
                  <path d="M12.8812 20.6384C14.0076 20.6578 13.8632 20.4838 13.8728 21.6051C13.8824 22.5235 13.9402 22.4075 12.9775 22.4075C11.9185 22.4171 11.9185 22.4075 11.9185 21.3634C11.9185 20.6384 11.9185 20.6384 12.6309 20.6384C12.7175 20.6384 12.7945 20.6384 12.8812 20.6384Z" className="fill-white" />
                  <path d="M40.7616 56.1446C47.5103 56.135 54.2493 56.135 60.998 56.1446C61.3253 56.1446 61.5179 56.077 61.6526 55.7386C62.0859 54.7043 62.856 54.0276 63.9728 53.8343C65.6479 53.5443 67.2075 54.5399 67.6696 56.1446C68.1318 57.759 67.3423 59.441 65.8116 60.1177C64.3483 60.7557 62.6057 60.1563 61.7778 58.6387C61.5371 58.2037 61.2868 58.0877 60.8247 58.0877C53.4984 58.0973 48.0783 58.0973 40.752 58.0973M64.5793 58.3197C65.2628 58.3197 65.8405 57.7493 65.8597 57.0533C65.879 56.3573 65.3014 55.7579 64.6178 55.7579C63.915 55.7579 63.347 56.3476 63.3374 57.0823C63.3374 57.759 63.9054 58.3197 64.5793 58.3197Z" fill="#FF0000" />
                  <path d="M41.3007 54.0278C42.1383 54.0278 42.9662 54.0471 43.8038 54.0085C43.9771 53.9988 44.16 53.8345 44.3044 53.6991C50.3984 47.8894 56.4828 42.07 62.5672 36.2505C63.7803 35.0905 65.0029 33.9402 66.2063 32.7608C66.3892 32.5772 66.5336 32.2678 66.5336 32.0165C66.5625 29.7158 66.5433 27.4151 66.5529 25.1047C66.5529 24.6794 66.4277 24.544 66.0041 24.5537C64.5889 24.5634 64.5889 24.544 64.5889 25.9941C64.5889 27.6761 64.5793 29.3581 64.5986 31.0498C64.6082 31.4461 64.4734 31.7168 64.1942 31.9875C56.685 39.1506 49.195 46.3137 41.6858 53.4865C41.5414 53.6218 41.3585 53.7281 41.1852 53.8441C41.2141 53.8925 41.2526 53.9601 41.3007 54.0278Z" className="fill-white" />
                  <path d="M68.6324 30.2859C68.6324 29.5996 68.6035 28.9132 68.6516 28.2269C68.6709 27.9562 68.796 27.6275 68.9886 27.4342C69.9417 26.4772 70.9236 25.5395 71.9249 24.6308C72.2618 24.3215 72.387 24.0702 72.2041 23.5868C71.7034 22.2335 72.2041 20.6868 73.3208 19.8554C74.4472 19.0241 75.9876 19.0144 77.1139 19.8361C78.2692 20.6771 78.7409 22.1561 78.2885 23.5578C77.8456 24.9498 76.623 25.8392 75.1307 25.7908C74.736 25.7812 74.6012 25.9262 74.4857 26.2549C72.8972 30.5566 71.2991 34.8487 69.701 39.1504C69.6336 39.3244 69.5662 39.4887 69.4796 39.7111C69.0175 39.5371 68.6227 39.3437 68.1991 39.2277C67.7467 39.0924 67.6696 38.899 67.8429 38.4544C69.1426 35.0033 70.423 31.5329 71.7034 28.0722C71.7708 27.8885 71.8286 27.7049 71.8864 27.5212C71.8479 27.5019 71.819 27.4825 71.7805 27.4632C70.7792 28.4299 69.7684 29.3966 68.7671 30.3633C68.719 30.3343 68.6709 30.3149 68.6324 30.2859ZM75.2559 21.1991C74.5435 21.1991 73.9466 21.8371 73.9755 22.5911C73.9947 23.2195 74.6205 23.7802 75.304 23.7608C75.9876 23.7415 76.4882 23.1808 76.4785 22.4655C76.4689 21.7501 75.9394 21.2088 75.2559 21.1991Z" fill="#FF0000" />
                  <path d="M64.3964 15.95C64.3675 16.0177 64.3482 16.0854 64.3097 16.1434C62.4228 18.6567 60.5359 21.1701 58.6682 23.6931C58.5527 23.8478 58.5527 24.1765 58.6297 24.3795C59.0725 25.5975 58.803 27.0282 57.8691 27.9079C56.8486 28.8746 55.6645 29.1162 54.3552 28.5749C52.786 27.9272 52.0254 26.1195 52.6127 24.4472C53.1711 22.8618 54.981 21.9241 56.5598 22.4461C56.9257 22.5621 57.0797 22.4171 57.2723 22.1658C60.1893 18.2797 63.116 14.413 66.0426 10.5269C66.1582 10.3723 66.2833 10.2273 66.3988 10.0823C66.4374 10.1016 66.4855 10.1209 66.524 10.1403C66.524 11.1166 66.524 12.093 66.524 13.0597C66.524 15.0704 66.5144 17.081 66.5336 19.0917C66.5336 19.4881 66.4181 19.6524 66.0138 19.6234C65.6576 19.5944 65.2917 19.6524 64.9355 19.5944C64.8007 19.5751 64.6274 19.3817 64.5889 19.2367C64.5312 19.0144 64.5697 18.7631 64.5697 18.5214C64.5697 17.6804 64.5697 16.849 64.5697 16.008C64.5119 15.9984 64.4542 15.9694 64.3964 15.95ZM55.626 24.3118C54.9425 24.3022 54.3841 24.8725 54.3648 25.5975C54.3456 26.2742 54.9328 26.8832 55.626 26.8832C56.3095 26.8929 56.8775 26.3128 56.8775 25.5975C56.8872 24.9015 56.3192 24.3215 55.626 24.3118Z" className="fill-white" />
                  <path d="M52.3239 16.0757C52.1602 16.3367 52.0639 16.472 51.9773 16.617C50.2829 19.4977 48.5885 22.3881 46.8845 25.2688C46.7016 25.5782 46.6727 25.8392 46.8171 26.1775C47.6547 28.1689 48.4537 30.1796 49.2913 32.1806C49.4357 32.5189 49.4164 32.7703 49.1854 33.0699C48.6944 33.6886 48.2419 34.3266 47.7413 35.0033C48.2612 35.4093 48.7425 35.7863 49.2817 36.2117C50.7643 34.2203 52.2276 32.2579 53.7006 30.2859C53.1614 29.9089 52.6416 29.5512 52.0928 29.1742C51.6981 29.6769 51.3034 30.1892 50.8605 30.7596C50.7643 30.5759 50.6872 30.4502 50.6295 30.3246C50.0807 28.9712 49.5223 27.6275 48.9929 26.2645C48.9158 26.0712 48.887 25.7715 48.9832 25.5975C49.9363 23.9445 50.9183 22.3204 51.9003 20.6771C51.9388 20.6094 52.0062 20.5611 52.1121 20.4451C52.3046 20.8221 52.4683 21.1508 52.6416 21.5084C53.2192 21.2184 53.7776 20.9478 54.3841 20.6481C53.7102 19.1497 53.0459 17.6707 52.3239 16.0757Z" fill="#FF0000" />
                  <path d="M46.9134 54.0277C48.5404 54.0277 50.1674 53.9987 51.7848 54.0374C52.9497 54.0664 54.0472 53.8924 55.1639 53.5541C58.3698 52.5777 61.6045 51.6497 64.8297 50.7314C65.2533 50.6057 65.5132 50.422 65.6287 49.987C65.7539 49.5423 65.9368 49.117 66.1197 48.6337C65.5036 48.392 64.9452 48.1793 64.4061 47.9763C64.329 48.0343 64.2809 48.0537 64.2713 48.073C64.1461 48.798 63.6166 49.001 62.9812 49.1847C57.7729 50.683 52.5646 52.22 47.3563 53.7474C47.2022 53.7957 47.0482 53.8537 46.8942 53.9021C46.8942 53.9504 46.9038 53.9891 46.9134 54.0277Z" fill="#FF0000" />
                  <path d="M44.7858 38.9185C43.7653 40.2815 42.7929 41.5768 41.9361 42.7272C42.3886 43.2589 42.7833 43.7229 43.2454 44.2545C44.2659 42.8915 45.2767 41.5478 46.3357 40.1268C45.8159 39.7208 45.3249 39.3438 44.7858 38.9185Z" className="fill-white" />
                  <path d="M67.5253 44.8732C67.9007 43.8485 68.2665 42.8818 68.6131 41.8958C68.642 41.8185 68.5169 41.6155 68.4206 41.5672C68.0162 41.3835 67.6023 41.2191 67.1787 41.0935C67.0728 41.0645 66.8417 41.1708 66.8032 41.2675C66.4566 42.1278 66.1293 42.9882 65.8212 43.8679C65.7827 43.9742 65.8694 44.2255 65.956 44.2642C66.447 44.4865 66.9572 44.6605 67.5253 44.8732Z" className="fill-white" />
                  <path d="M47.568 38.5028C47.9339 38.0098 48.2612 37.5747 48.6174 37.0914C48.0975 36.6854 47.6162 36.3084 47.077 35.8831C46.7305 36.3471 46.3935 36.7917 46.0373 37.2751C46.5572 37.6907 47.0289 38.0678 47.568 38.5028Z" className="fill-white" />
                  <path d="M65.571 20.7063C64.4446 20.7256 64.589 20.5516 64.5794 21.673C64.5697 22.5913 64.512 22.4753 65.4747 22.4753C66.5337 22.485 66.5337 22.4753 66.5337 21.4313C66.5337 20.7063 66.5337 20.7063 65.8213 20.7063C65.7346 20.7063 65.6576 20.7063 65.571 20.7063Z" className="fill-white" />
                  <path d="M85.634 8.92236L88.2237 23.9253L91.7954 17.4098H94.5681L98.1398 23.9253L100.729 8.92236H103.666L100.219 28.8167H97.5718L93.1721 20.8125L88.7821 28.8167H86.125L82.6785 8.92236H85.634Z" className="fill-white" />
                  <path d="M106.592 8.92236H121.958V11.8611H109.529V25.8683H121.958V28.8167H106.592V8.92236ZM111.204 16.9942H121.958V19.9329H111.204V16.9942Z" className="fill-white" />
                  <path d="M125.587 8.92236H136.023C136.947 8.92236 137.775 9.1447 138.516 9.57971C139.258 10.0147 139.854 10.6044 140.297 11.3391C140.74 12.0737 140.962 12.9051 140.962 13.8138V15.1188C140.962 16.4141 140.586 17.4002 139.845 18.0672C140.5 18.4925 140.981 19.0048 141.279 19.5945C141.578 20.1842 141.722 20.9285 141.722 21.8082V24.0026C141.722 24.8823 141.501 25.6846 141.058 26.4096C140.615 27.1346 140.028 27.7146 139.277 28.1497C138.536 28.5847 137.708 28.7973 136.812 28.7973H125.577V8.92236H125.587ZM136.841 25.8586C137.38 25.8586 137.842 25.6749 138.218 25.3076C138.603 24.9403 138.786 24.4956 138.786 23.9736V21.7792C138.786 21.2572 138.593 20.8125 138.218 20.4355C137.833 20.0585 137.371 19.8749 136.822 19.8749H130.189V16.9361H136.013C136.562 16.9361 137.034 16.7525 137.428 16.3948C137.823 16.0371 138.025 15.6021 138.025 15.0898V13.8138C138.025 13.2724 137.823 12.8084 137.428 12.4314C137.034 12.0447 136.562 11.8611 136.013 11.8611H128.523V25.8683H136.841V25.8586Z" className="fill-white" />
                  <path d="M82.9288 44.2738C82.9288 43.2395 83.1888 42.3018 83.699 41.4511C84.2092 40.6101 84.8928 39.9334 85.7496 39.4308C86.6064 38.9281 87.5499 38.6768 88.5896 38.6768H98.9678V41.6155H88.58C87.8291 41.6155 87.184 41.8765 86.6545 42.3888C86.1251 42.9108 85.8651 43.5295 85.8651 44.2738V53.032C85.8651 53.7376 86.1347 54.3467 86.6545 54.859C87.184 55.3713 87.8291 55.6227 88.58 55.6227H98.9678V58.5711H88.58C87.5403 58.5711 86.5968 58.3197 85.74 57.8267C84.8831 57.3337 84.1996 56.6667 83.6894 55.8353C83.1791 55.004 82.9192 54.076 82.9192 53.0706V44.2738H82.9288Z" fill="#FF0000" />
                  <path d="M102.453 38.6768H112.927C113.832 38.6768 114.65 38.8991 115.382 39.3438C116.123 39.7884 116.711 40.3781 117.154 41.1128C117.596 41.8475 117.818 42.6691 117.818 43.5778V45.5692C117.818 46.4779 117.606 47.2899 117.173 48.0149C116.74 48.7399 116.152 49.3199 115.411 49.7453C114.67 50.1706 113.832 50.3929 112.918 50.3929L118.829 58.5807H115.238L109.327 50.3929H107.055V47.4542H112.918C113.466 47.4542 113.928 47.2609 114.314 46.8839C114.699 46.5069 114.882 46.0525 114.882 45.5209V43.5778C114.882 43.0365 114.689 42.5725 114.314 42.1955C113.928 41.8088 113.466 41.6251 112.918 41.6251H105.379V58.5807H102.443V38.6768H102.453Z" fill="#FF0000" />
                  <path d="M121.611 44.2738C121.611 43.2395 121.881 42.3018 122.41 41.4511C122.94 40.6101 123.662 39.9334 124.547 39.4308C125.443 38.9281 126.425 38.6768 127.512 38.6768H132.153C133.241 38.6768 134.232 38.9281 135.128 39.4308C136.023 39.9334 136.735 40.6101 137.265 41.4511C137.794 42.2921 138.064 43.2395 138.064 44.2738V52.974C138.064 53.9987 137.794 54.9363 137.265 55.787C136.735 56.6377 136.023 57.3144 135.128 57.817C134.232 58.3197 133.241 58.5711 132.153 58.5711H127.512C126.425 58.5711 125.433 58.3197 124.547 57.817C123.652 57.3144 122.94 56.6377 122.41 55.787C121.881 54.9363 121.611 53.9987 121.611 52.974V44.2738ZM124.547 52.9933C124.547 53.7183 124.836 54.3467 125.414 54.859C125.991 55.381 126.694 55.6323 127.512 55.6323H132.153C132.981 55.6323 133.683 55.3713 134.261 54.859C134.839 54.337 135.128 53.7183 135.128 52.9933V44.2642C135.128 43.5392 134.839 42.9108 134.261 42.3985C133.683 41.8765 132.981 41.6251 132.153 41.6251H127.512C126.694 41.6251 125.991 41.8861 125.414 42.3985C124.836 42.9205 124.547 43.5392 124.547 44.2642V52.9933Z" fill="#FF0000" />
                  <path d="M143.667 38.6768L146.257 53.6796L149.828 47.1642H152.601L156.173 53.6796L158.762 38.6768H161.699L158.262 58.5711H155.614L151.215 50.5669L146.825 58.5711H144.168L140.721 38.6768H143.667Z" fill="#FF0000" />
                  <path d="M164.625 38.6768H168.447L177.073 54.308V38.6768H180.01V58.5711H176.188L167.562 43.1525V58.5711H164.625V38.6768Z" fill="#FF0000" />
                </g>
                <defs>
                  <clipPath id="clip0_468_2306">
                    <rect width="180" height="60.3692" className="fill-white" />
                  </clipPath>
                </defs>
              </svg>
            </Link>
          </div>
          <Magnetic>
            <div ref={stickyElement}
              onClick={toggleMenu}
              className="cursor-pointer z-10 rounded-full bg-white/20 p-2"
            >
              <svg className="w-8 h-8" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.28308 19L20 19M20 5L12.9719 5" stroke="currentColor" strokeWidth="1" strokeLinecap="round"></path>
                <path d="M19.9996 12L4 12" stroke="#ff0000" strokeWidth="1" strokeLinecap="round"></path>
              </svg>
            </div>
          </Magnetic>
        </div>


        <motion.div
          initial={false}
          animate={isOpen ? 'open' : 'closed'}
          variants={menuVariants}
          className="fixed top-0 left-0 w-full h-screen z-50 bg-black overflow-y-auto"
        >
          <div className="h-full">
            <div ref={stickyElement} onClick={toggleMenu} className="cursor-pointer rounded-full bg-white/20 p-2 fixed z-30 right-4 top-4">
              <svg fill="currentColor" className="w-8 h-8 p-1" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
                <path d="M29.484 0c-.13.004-.252.057-.343.15L17.164 12.13c-.49.47.235 1.197.706.707L29.846.857c.325-.318.1-.857-.363-.857zM12.488 17c-.13.004-.25.058-.34.15L.162 29.14c-.486.467.233 1.186.7.7L12.848 17.85c.325-.313.093-.85-.36-.85zM.5 0a.5.5 0 0 0-.348.86L29.14 29.845a.5.5 0 1 0 .706-.706L.86.152A.5.5 0 0 0 .5 0z"></path>
              </svg>
            </div>

            <div className="wc-menu h-full">

              <div className="flex flex-col justify-between items-center h-full">
                {
                  menuitems.map((menulist, index) => {
                    return (
                      <Link to={menulist.link} key={menulist.id} className='project' onClick={closeMenu}>
                        <Menulist index={index} title={menulist.title} subtitle={menulist.subtitle} setModal={setModal} />
                      </Link>
                    );
                  })
                }
              </div>

              <Modal modal={modal} menuitems={menuitems} />
            </div>

          </div>
        </motion.div>

      </header>
    </>
  );
}