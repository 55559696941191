import { Magnetic } from '@/common';
import { AnimatedCursorContext } from '@/cursor';
import Homebanner from '@/section/homebanner';
import Ourservices from '@/section/ourservices';
import ServicesCard from '@/section/servicescard';
import Video from '@/section/video';
import WhatWeDo from '@/section/whatwedo';
import WhoWeAre from '@/section/whoweare';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
export function Home() {
  const [currentURL, setCurrentURL] = useState(window.location.href);
  useEffect(() => {
    setCurrentURL(window.location.href);
  }, []);

  const stickyElement = useRef(null);
  const { cursorStyleHandler } = useContext(AnimatedCursorContext);
  const textEnter = () => {
    cursorStyleHandler("linkHover");
  };
  const textLeave = () => {
    cursorStyleHandler("default");
  };
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Web Crown</title>
          <meta name="title" content="Web Crown" />
          <meta name="og:url" content={currentURL} />
          <meta name="og:image" content="./img/logo.svg" />
        </Helmet>
      </HelmetProvider>
      <motion.div
        className="h-full relative z-0"
        initial={{ y: "-200vh" }}
        animate={{ y: "0%" }}
        transition={{ duration: 1 }}
      >
      <Homebanner />
      </motion.div>
      <Video />
      <WhatWeDo />
      <WhoWeAre />
      <Ourservices />
      <ServicesCard />
      <div className="mx-auto w-fit">
        <Magnetic>
          <Link ref={stickyElement} onMouseEnter={textEnter} onMouseLeave={textLeave} to="/services" className="btn-white-outline mx-auto mt-10" ><span>More Services</span></Link>
        </Magnetic>
      </div>
      
    </>
  );
}

export default Home;
