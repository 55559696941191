import React, {useContext, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { motion } from 'framer-motion';
import gsap from 'gsap';
import { AnimatedCursorContext } from "@/cursor/";
import TitleAnimation from "@/common/titleanimation";
import { disperse } from "@/component/anim";

const year = new Date().getFullYear();
function Footer() {
  const background = useRef(null);

  const setBackground = (isActive) => {
    gsap.to(background.current, { opacity: isActive ? 0.8 : 0 })
  }
  const { cursorStyleHandler } = useContext(AnimatedCursorContext);
    const textEnter = () => {
        cursorStyleHandler("linkHover");
    };
    const textLeave = () => {
        cursorStyleHandler("default");
    };
  return (
    <footer className="sec-padding px-0 !pb-0 relative z-1">
      <div ref={background} className="background"></div>
      <div className='container'>
        <div className="flex justify-between flex-col md:flex-row items-center">
          <div className="relative text-center md:text-left w-fit mx-auto md:ml-0 md:mr-auto px-10">
            <TitleAnimation text1="time to" text2="connect" />
          </div>
          <div className="mx-auto md:ml-auto md:mr-0 relative z-1">
            <Link to="/contact" className="btn-white"
            onMouseEnter={textEnter} onMouseLeave={textLeave}
            >Let’s talk!</Link>
          </div>
        </div>
        <div className="border-t border-b border-white mt-10 lg:mt-24 py-10">
          <div className="footer-add-box">
            <div className="footer_add">
              <span>Email</span>
              <Link className="relative z-1" to="mailto:support@webcrown.co.in">
                <TextDipserse setBackground={setBackground}>
                  <p>support@webcrown.co.in</p>
                </TextDipserse>
              </Link>
            </div>
            <div className="footer_add">
              <span>Call</span>
              <Link className="relative z-1" to="tel:9427421805">
                <TextDipserse setBackground={setBackground}>
                  <p>+919427421805</p>
                </TextDipserse>
              </Link>
            </div>
            <div className="footer_add">
              <span>Skype</span>
              <Link className="relative z-1" to="/">
                <TextDipserse setBackground={setBackground}>
                  <p>talk.Webcrown</p>
                </TextDipserse>
              </Link>
            </div>
          </div>
        </div>
        <div className="py-10">
          <div className="flex justify-center lg:justify-start">
            <div className="text-xl uppercase text-white flex items-center gap-2">
              <svg fill="currentColor" className="w-5 h-5" viewBox="0 0 367.467 367.467"><path d="M183.73,0.018C82.427,0.018,0,82.404,0,183.733c0,101.289,82.427,183.716,183.73,183.716 c101.315,0,183.737-82.427,183.737-183.716C367.467,82.404,285.045,0.018,183.73,0.018z M183.73,326.518 c-78.743,0-142.798-64.052-142.798-142.784c0-78.766,64.055-142.817,142.798-142.817c78.752,0,142.807,64.052,142.807,142.817 C326.536,262.466,262.481,326.518,183.73,326.518z"></path> <path d="M244.036,217.014c-11.737,20.141-33.562,32.635-56.956,32.635c-36.329,0-65.921-29.585-65.921-65.915 c0-36.36,29.592-65.955,65.921-65.955c23.395,0,45.219,12.54,56.956,32.641l1.517,2.627h44.28l-2.658-7.129 c-7.705-20.413-21.225-37.769-39.122-50.157c-17.942-12.42-39.017-19.009-60.973-19.009c-58.981,0-106.946,48.006-106.946,106.982 c0,58.98,47.965,106.941,106.946,106.941c21.956,0,43.03-6.567,60.973-19.006c17.897-12.391,31.417-29.741,39.122-50.154 l2.658-7.133h-44.28L244.036,217.014z"></path></svg>
              {year} Web crown
            </div>
          </div>
        </div>
      </div>
    </footer >
  )
}

function TextDipserse(props) {

  const { children, setBackground } = props;

  const [isAnimated, setIsAnimated] = useState(false);

  const getChars = (element) => {
    let chars = [];
    if (children.length) {
      children.forEach((el, i) => {
        chars.push(splitWord(el.props.children, i))
      })
    }
    else {
      chars.push(splitWord(element.props.children, 1))
    }
    return chars;
  }

  const splitWord = (word, indexOfWord, ) => {
    let chars = [];
    word.split("").forEach((char, i) => {
      chars.push(<motion.span custom={indexOfWord * i} variants={disperse} animate={isAnimated ? "open" : "closed"} key={char + i}>{char}</motion.span>)
    })
    return chars;
  }

  const manageMouseEnter = () => {
    setBackground(true)
    setIsAnimated(true);
  }
  const manageMouseLeave = () => {
    setBackground(false)
    setIsAnimated(false);
  }

  return (
    <div onMouseEnter={() => { manageMouseEnter() }} onMouseLeave={() => { manageMouseLeave(false) }} className='introLine'>
      {getChars(children)}
    </div>
  )
}

export default Footer
