import React, { useEffect, useRef } from 'react';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { gsap } from 'gsap';



function Ourservices() {
  const moonRef = useRef(null);
  const clouds1Ref = useRef(null);
  const clouds2Ref = useRef(null);
  const clouds3Ref = useRef(null);
  const clouds4Ref = useRef(null);
  const clouds5Ref = useRef(null);
  const clouds6Ref = useRef(null);
  const palaceRef = useRef(null);
 
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.fromTo(moonRef.current,
      { x: -220, opacity: 0.1, },
      { x: 440, duration: 1, opacity: 0.7, scrollTrigger: { trigger: moonRef.current, scrub: 40, pin: false, start: "bottom bottom", } });

    gsap.fromTo(clouds1Ref.current,
      { x: 0, y: 0, opacity: 1, scale: 1, },
      { x: 800, y: 0, duration: 1, opacity: 1, scale: 0.6, scrollTrigger: { trigger: clouds1Ref.current, scrub: 4, pin: false, start: "bottom bottom", } });

    gsap.fromTo(clouds2Ref.current,
      { x: 0, y: 0, opacity: 1, scale: 1, },
      { x: 500, y: 0, duration: 1, opacity: 1, scale: 0.8, scrollTrigger: { trigger: clouds2Ref.current, scrub: 4, pin: false, start: "bottom bottom", } });

    gsap.fromTo(clouds3Ref.current,
      { x: 0, y: 110, opacity: 1, scale: 1, },
      { x: -800, y: 300, duration: 1, opacity: 1, scale: 0.4, scrollTrigger: { trigger: clouds2Ref.current, scrub: 4, pin: false, start: "bottom bottom", } });

    gsap.fromTo(clouds4Ref.current,
      { x: 0, y: 0, opacity: 1, scale: 1, },
      { x: -600, y: -300, duration: 1, opacity: 1, scale: 2, scrollTrigger: { trigger: clouds2Ref.current, scrub: 4, pin: false, start: "bottom bottom", } });

    gsap.fromTo(clouds5Ref.current,
      { x: 0, y: 0, opacity: 1, scale: 1, },
      { x: -600, y: -300, duration: 1, opacity: 1, scale: 3, scrollTrigger: { trigger: clouds2Ref.current, scrub: 4, pin: false, start: "bottom bottom", } });

    gsap.fromTo(clouds6Ref.current,
      { x: 0, y: 0, opacity: 1, scale: 1, },
      { x: -600, y: -40, duration: 1, opacity: 1, scale: 2, scrollTrigger: { trigger: clouds2Ref.current, scrub: 4, pin: false, start: "bottom bottom", } });

    gsap.fromTo(palaceRef.current,
      { x: 0, y: 0, opacity: 0.8, scale: 1, },
      { x: -500, y: 0, duration: 1, opacity: 1, scale: 2, scrollTrigger: { trigger: palaceRef.current, scrub: 4, pin: false, start: "bottom bottom", } });

  }, []);
  
  return (
    <section className='sec-padding px-0 !pt-0 relative z-0 min-h-screen hidden lg:block' >
      <div className="stars"></div>
      <div className="twinkling"></div>
      <div ref={moonRef} className='absolute z-50'>
         <img
          src="./img/moon.svg"
          width={440}
          height={440}
          alt="Picture of the author"
          decoding="async"
          loading="lazy"
           
        />
      </div>
      <div ref={clouds1Ref} className='absolute z-50 left-28 top-36'>
         <img
          src="./img/clouds1.svg"
          width={352}
          height={186}
          alt="Picture of the author"
         decoding="async"
          loading="lazy"
           
        />
      </div>
      <div ref={clouds2Ref} className='absolute z-50 left-2/4 top-24'>
         <img
          src="./img/clouds2.svg"
          width={352}
          height={186}
          alt="Picture of the author"
         decoding="async"
          loading="lazy"
           
        />
      </div>
      <div ref={clouds3Ref} className='absolute z-50 right-9 top-36'>
         <img
          src="./img/clouds3.svg"
          width={220}
          height={183}
          alt="Picture of the author"
          decoding="async"
          loading="lazy"
          className="h-[183px] w-[220px]"
           
        />
      </div>
      <div ref={clouds4Ref} className='absolute z-50 right-64 bottom-96'>
         <img
          src="./img/clouds4.svg"
          width={198}
          height={145}
          alt="Picture of the author"
         decoding="async"
          loading="lazy"
           
        />
      </div>
      <div ref={clouds5Ref} className='absolute z-50 right-2/4 bottom-96'>
         <img
          src="./img/clouds5.svg"
          width={128}
          height={165}
          alt="Picture of the author"
         decoding="async"
          loading="lazy"
           
         
        />
      </div>
      <div ref={clouds6Ref} className='absolute z-50 left-1/4 bottom-96'>
         <img
          src="./img/clouds6.svg"
          width={162}
          height={86}
          alt="Picture of the author"
          className=""
        />
      </div>

      <div ref={palaceRef} className='absolute z-50 right-0 bottom-0'>
         <img
          src="./img/palace.svg"
          width={955}
          height={546}
          decoding="async"
          loading="lazy"
          alt="Picture of the author"
          className="h-[546px] w-[955px]"
           
        />
      </div>
      
    </section>
  )
}

export default Ourservices;
