import React, { useContext } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import { useRef} from 'react';
import { useInView } from 'react-intersection-observer';
import { Magnetic } from "@/common/magnetic";
import TitleAnimation from "@/common/titleanimation";
import { AnimatedCursorContext } from "@/cursor/animatedcursormanager";

export const projects = [
  {
    id: 0,
    title: "Laravel",
  },
  {
    id: 1,
    title: "PHP",
  },
  {
    id: 2,
    title: "Android",
  },
  {
    id: 3,
    title: "Web Design",
  },
  {
    id: 4,
    title: "Tailwind",
  },
  {
    id: 5,
    title: "Bootstrap",
  },
  {
    id: 6,
    title: "Next Js",
  },
  {
    id: 7,
    title: "React Js",
  },
  {
    id: 8,
    title: "AWS",
  },

];


function WhatWeDo() {
  const { cursorStyleHandler } = useContext(AnimatedCursorContext);

    const textEnter = () => {
        cursorStyleHandler("blur");
    };

    const textLeave = () => {
        cursorStyleHandler("default");
    };
    
  const boxView = useRef(null);
  const { scrollYProgress: boxScrollYProgress } = useScroll({
    target: boxView,
    offset: ["start 0.9 ", "end 0.9 "]
  });
  // Add useInView hook
  const { ref, inView } = useInView({
    triggerOnce: true, // Trigger animation only once
    threshold: 1, // Trigger animation when 50% of the element is visible
  });
  const scale = useTransform(boxScrollYProgress, [0, 0.5], [0, 1]);
  return (

    <section className='sec-padding px-2 z-0 pt-20'>
      <div className="relative text-center w-fit mx-auto">
        <TitleAnimation text1="What" text2="We Do" />
      </div>

      <motion.div
        className="mt-10 relative z-1 what-we-do-sec"
        initial={{ scale: 1, rotate: 10 }}
        style={{ scale }}
        ref={boxView} 

      >
        <motion.div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3'

        >
          {projects.map((project,) => (
            <div key={project.id}
             className="relative group block p-2 h-full w-full z-10" onMouseEnter={textEnter} onMouseLeave={textLeave}
            >
              <Magnetic>
                <div className="whatwecan-box">
                  <span></span>
                  <div className="item">
                    <h3>
                      {project.title}
                    </h3>
                  </div>
                </div>
              </Magnetic>
            </div>
          ))}
        </motion.div>
      </motion.div>

    </section>
  )
}

export default WhatWeDo
