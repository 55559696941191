import React, { useContext } from 'react';

import { AnimatedCursorContext } from '@/cursor/animatedcursormanager';

import AnimatedTextWord from '@/component/animatedtextword';
import Paragraph from '@/description/paragraph';


const SubBanner = ({ title, content }) => {
  const { cursorStyleHandler } = useContext(AnimatedCursorContext);

  const textEnter = () => {
    cursorStyleHandler("hover");
  };

  const textLeave = () => {
    cursorStyleHandler("default");
  };
  return (
    <section className='wcrown-banner relative z-0'>
      <div className='text-center flex flex-col items-center justify-center'>
        <div className='w-full lg:w-3/4 xl:w-4/5 mx-auto px-10 lg:px-0'>
          <h1 className="title-text" onMouseEnter={textEnter} onMouseLeave={textLeave}>
            <AnimatedTextWord text={title} />
          </h1>
          <div className="title-con common-fonts">
            <Paragraph paragraph={content} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default SubBanner;