import React from 'react';
import { motion } from "framer-motion";
const variants = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 }
      }
    },
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 }
      }
    }
  };
export function Menulist({index, title, subtitle, setModal,}) {

    return (
        <motion.div  variants={variants} onMouseEnter={() => {setModal({active: true, index})}} onMouseLeave={() => {setModal({active: false, index})}} className='linkbox'>
            <h2>{title}</h2>
            <p>{subtitle}</p>
        </motion.div>
        
    )
}
export default Menulist;