
export const opacity = {
  initial: {
      opacity: 0
  },
  enter: {
      opacity: 0.75,
      transition: {duration: 1, delay: 0.2}
  },
}

export const slideUp = {
  initial: {
      top: 0
  },
  exit: {
      top: "-100vh",
      transition: {duration: 0.8, ease: [0.76, 0, 0.24, 1], delay: 0.2}
  }
}
export const perspective = {
  initial: {
      opacity: 0,
      rotateX: 90,
      translateY: 0,
      translateX: 100,
  },
  enter: (i) => ({
      opacity: 1,
      rotateX: 0,
      translateY: 0,
      translateX: 0,
      transition: {
          duration: 0.65, 
          delay: 0.5 + (i * 0.1), 
          ease: [.215,.61,.355,1],
          opacity: { duration: 0.35}
      }
  }),
  exit: {
      opacity: 0,
      transition: { duration: 0.5, type: "linear", ease: [0.76, 0, 0.24, 1]}
  }
}

export const slideIn = {
  initial: {
      opacity: 0,
      y: 20
  },
  enter: (i) => ({
      opacity: 1,
      y: 0,
      transition: { 
          duration: 0.5,
          delay: 0.75 + (i * 0.1), 
          ease: [.215,.61,.355,1]
      }
  }),
  exit: {
      opacity: 0,
      transition: { duration: 0.5, type: "tween", ease: "easeInOut"}
  }
}
export const transforms = [
    {
        x: -0.8,
        y: -0.6,
        rotationZ: -29
    },
    {
        x: -0.2,
        y: -0.4,
        rotationZ: -6
    },
    {
        x: -0.05,
        y: 0.1,
        rotationZ: 12
    },
    {
        x: -0.05,
        y: -0.1,
        rotationZ: -9
    },
    {
        x: -0.1,
        y: 0.55,
        rotationZ: 3
    },
    {
        x: 0,
        y: -0.1,
        rotationZ: 9
    },
    {
        x: 0,
        y: 0.15,
        rotationZ: -12
    },
    {
        x: 0,
        y: 0.15,
        rotationZ: -17
    },
    {
        x: 0,
        y: -0.65,
        rotationZ: 9
    },
    {
        x: 0.1,
        y: 0.4,
        rotationZ: 12
    },
    {
        x: 0,
        y: -0.15,
        rotationZ: -9
    },
    {
        x: 0.2,
        y: 0.15,
        rotationZ: 12
    },
    {
        x: 0.8,
        y: 0.6,
        rotationZ: 20
    },
    {
        x: -0.8,
        y: -0.6,
        rotationZ: -29
    },
    {
        x: -0.2,
        y: -0.4,
        rotationZ: -6
    },
    {
        x: -0.05,
        y: 0.1,
        rotationZ: 12
    },
    {
        x: -0.05,
        y: -0.1,
        rotationZ: -9
    },
    {
        x: -0.1,
        y: 0.55,
        rotationZ: 3
    },
    {
        x: 0,
        y: -0.1,
        rotationZ: 9
    },
    {
        x: 0,
        y: 0.15,
        rotationZ: -12
    },
    {
        x: 0,
        y: 0.15,
        rotationZ: -17
    },
    {
        x: 0,
        y: -0.65,
        rotationZ: 9
    },
    {
        x: 0.1,
        y: 0.4,
        rotationZ: 12
    },
    {
        x: 0,
        y: -0.15,
        rotationZ: -9
    },
    {
        x: 0.2,
        y: 0.15,
        rotationZ: 12
    },
    

    
]
export const disperse = {
    open: (i) => ({
      x: transforms[i].x + "em",
      y: transforms[i].y + "em",
      rotateZ: transforms[i].rotationZ,
      transition: {duration: 0.75, ease: [0.33, 1, 0.68, 1]},
      zIndex: 1
    }),
    closed: {
      x: 0,
      y: 0,
      rotateZ: 0,
      transition: {duration: 0.75, ease: [0.33, 1, 0.68, 1]},
      zIndex: 0
    }
}
