import { useState, useEffect } from "react";

const useMousePosition = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const onMouseMove = (event) => {
      const { clientX, clientY } = event;
      // Smooth out the mouse movement by calculating the average of current and previous positions
      setMousePosition(prevPosition => ({
        x: (prevPosition.x + clientX) / 2,
        y: (prevPosition.y + clientY) / 2
      }));
    };

    window.addEventListener("mousemove", onMouseMove);

    return () => {
      window.removeEventListener("mousemove", onMouseMove);
    };
  }, []);

  return mousePosition;
};

export default useMousePosition;
