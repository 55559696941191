import { useRef, useState } from 'react'
import { motion } from 'framer-motion';

export function Magnetic({children}) {
    const magneticLink = useRef(null);
    const [position, setPosition] = useState({x:0,y:0});

    const handleMouse = (e) => {
        const { clientX, clientY } = e;
        const {height, width, left, top} = magneticLink.current.getBoundingClientRect();
        const middleX = clientX - (left + width/2)
        const middleY = clientY - (top + height/2)
        setPosition({x: middleX, y: middleY})
    }

    const reset = () => {
        setPosition({x:0, y:0})
    }

    const { x, y } = position;
    return (
        <motion.div
            className='relative z-1'
            ref={magneticLink}
            onMouseMove={handleMouse}
            onMouseLeave={reset}
            animate={{x, y}}
            transition={{type: "spring", stiffness: 100, damping: 20, mass: 0.1}}
        >
            {children}
        </motion.div>
    )
}
export default Magnetic;