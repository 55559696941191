import SubBanner from "@/common/subbanner";
import TitleAnimation from "@/common/titleanimation";
import { motion } from "framer-motion";
import { Link } from 'react-router-dom';
import { useRef, React, useState, useEffect } from "react";
import gsap from 'gsap';
import { disperse } from '@/component/anim';
import { Helmet, HelmetProvider } from "react-helmet-async";

export function Contact() {
  const containerRef = useRef();
  const background = useRef(null);

  const setBackground = (isActive) => {
    gsap.to(background.current, { opacity: isActive ? 0.8 : 0 })
  }

  const [currentURL, setCurrentURL] = useState(window.location.href);
  useEffect(() => {
    setCurrentURL(window.location.href);
  }, []);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Contact Us</title>
          <meta name="title" content="Contact Us - Web Crown" />
          <meta name="og:url" content={currentURL} />
          <meta name="og:image" content="./img/logo.svg" />
        </Helmet>
      </HelmetProvider>

      <motion.div
        className="h-full relative z-0"
        initial={{ y: "-200vh" }}
        animate={{ y: "0%" }}
        transition={{ duration: 1 }}
      >
        <SubBanner
          title="Orchestrating digital conversations with you."
          content="Empowering businesses with transformative tech solutions. Driving innovation through agile development and strategic branding. Your partner for navigating the digital landscape with confidence."
        />
      
      </motion.div>
      <div className='container'>
          <section className="relative z-1">
            <div className="relative text-center w-fit mx-auto px-10">
              <TitleAnimation text1="time to" text2="connect" />
            </div>
            <div className="contact-details">
              <div className="info">
                <div className="footer_add items-start">
                  <span className="label">Location</span>
                  <div className="">
                    <h3 className="text-3xl uppercase mb-5">WEB Crown</h3>
                    <Link target="_blank" to="https://maps.app.goo.gl/KBVJ25xQTexXKZ166">
                      <p>1407, Wings Business Bay, 150 Feet Ring Road, Nr. ITC Fortune Hotel, Rajkot, Gujarat - 360004</p>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="info">
                <div className="footer_add items-start relative z-10">
                  <span className="label">Email</span>
                  <Link to="mailto:support@webcrown.co.in">
                    <TextDipserse setBackground={setBackground}>
                      <p>support@webcrown.co.in</p>
                    </TextDipserse>
                  </Link>
                </div>
              </div>
              <div className="info">
                <div className="footer_add items-start">
                  <span className="label">Call</span>
                  <Link to="tel:9427421805">
                    <TextDipserse setBackground={setBackground}>
                      <p>+919427421805</p>
                    </TextDipserse>
                  </Link>
                </div>
              </div>
              <div className="info">
                <div className="footer_add items-start">
                  <span className="label">Skype</span>
                  <Link to="#">
                    <TextDipserse setBackground={setBackground}>
                      <p>talk.Webcrown</p>
                    </TextDipserse>
                  </Link>
                </div>
              </div>
              <div className="info">
                <div className="footer_add items-start">
                  <span className="label">Follow on</span>
                  <div className="social_list">
                    <Link target="_blank" to="https://www.linkedin.com/company/web-crown/">
                      <TextDipserse setBackground={setBackground}>
                        <p>Linkedin</p>
                      </TextDipserse>
                    </Link>
                    <Link target="_blank" to="https://www.instagram.com/web_crown_12/">
                      <TextDipserse setBackground={setBackground}>
                        <p>Instagram</p>
                      </TextDipserse>
                    </Link>
                    <Link target="_blank" to="https://x.com/webcrown12">
                      <TextDipserse setBackground={setBackground}>
                        <p>Twitter</p>
                      </TextDipserse>
                    </Link>
                    <Link target="_blank" to="https://www.facebook.com/profile.php?id=61559257821840">
                      <TextDipserse setBackground={setBackground}>
                        <p>Facebook</p>
                      </TextDipserse>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
    </>
  )
}

function TextDipserse(props) {
  const { children, setBackground } = props;
  const [isAnimated, setIsAnimated] = useState(false);

  const getChars = (element) => {
    let chars = [];
    if (element.length) {
      element.forEach((el, i) => {
        chars.push(splitWord(el.props.children, i));
      });
    } else {
      chars.push(splitWord(element.props.children, 1));
    }
    return chars;
  };

  const splitWord = (word, indexOfWord) => {
    let chars = [];
    word.split("").forEach((char, i) => {
      chars.push(
        <motion.span
          custom={indexOfWord * i}
          variants={disperse}
          animate={isAnimated ? "open" : "closed"}
          key={char + i}
        >
          {char}
        </motion.span>
      );
    });
    return chars;
  };

  const manageMouseEnter = () => {
    setBackground(true);
    setIsAnimated(true);
  };
  const manageMouseLeave = () => {
    setBackground(false);
    setIsAnimated(false);
  };

  return (
    <div
      onMouseEnter={manageMouseEnter}
      onMouseLeave={manageMouseLeave}
      className='introLine'
    >
      {getChars(children)}
    </div>
  );
}

export default Contact;
