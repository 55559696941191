import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { motion, useScroll, useTransform } from "framer-motion";
import { useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { Magnetic } from "@/common/magnetic";
import TitleAnimation from "@/common/titleanimation";
import Character from "@/description/character";
import { AnimatedCursorContext } from "@/cursor/animatedcursormanager";


const paragraph = "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout."

export function WhoWeAre() {
    const words = paragraph.split(" ")
    const stickyElement = useRef(null);
    const imgView = useRef(null);
    const { scrollYProgress: imgScrollYProgress } = useScroll({
        target: imgView,
        offset: ["start 0.9 ", "end 0.9 "]
    });

    // Add useInView hook
    const { ref } = useInView({
        triggerOnce: true, // Trigger animation only once
        threshold: 1, // Trigger animation when 50% of the element is visible

    });
    const scale = useTransform(imgScrollYProgress, [0.4, 1], [0.4, 1]);
    const { cursorStyleHandler } = useContext(AnimatedCursorContext);
    const textEnter = () => {
        cursorStyleHandler("linkHover");
    };
    const textLeave = () => {
        cursorStyleHandler("default");
    };

    return (
        <section className='sec-padding px-0 !pt-0 relative z-1' >
            <div className="relative text-center w-fit mx-auto">
                <TitleAnimation text1="Who" text2="We Are" />
            </div>
            <div className="w-full flex flex-col lg:flex-row mt-10 relative z-0">
                <motion.div
                    className="w-full lg:w-1/2 mt-5 lg:mt-10 about-img-view"
                    initial={{ scale: 0, rotate: -5, }}
                    whileInView={{
                        rotate: -5,
                    }}
                    style={{ scale, transformOrigin: 'left' }}
                    ref={imgView}
                >
                    <motion.div className='overflow-hidden '
                        initial={{
                            opacity: 0,
                            x: 100,

                        }}
                        whileInView={{
                            x: -100,
                            opacity: 1,
                        }
                        }

                        transition={{
                            duration: 2
                        }}
                    >

                         <img
                            src="./img/who-we-are.webp"
                            width={1920}
                            height={500}
                            alt="Picture of the author"
                             
                            className="w-[100%] object-cover rounded-[50px]"
                        />

                    </motion.div>
                </motion.div>

                <motion.div

                    className="w-full lg:w-1/2 mx-auto mt-5 lg:mt-10 sm-rotate-none"
                    initial={{ scale: 1, opacity: 1, rotate: 0, y: 0, x: -600 }}
                    whileInView={{
                        scale: 1,
                        rotate: -5,
                        y: 0,
                        opacity: 1,
                        x: 0
                    }}
                    transition={{
                        duration: 2
                    }}
                >
                    <div className="px-6 common-fonts">

                        <Character paragraph="Web Crown is a leading IT solutions provider dedicated to revolutionizing global business practices. Combining business acumen with technical expertise, we deliver unparalleled value through innovation and cost-saving strategies. Committed to empowering businesses and changing lives, we strive to make a positive impact on the world, one client at a time." />
                        <div className="mx-auto w-fit">
                            <Magnetic>
                                <Link ref={stickyElement} onMouseEnter={textEnter} onMouseLeave={textLeave}  to="/about" className="btn-white mx-auto mt-10 z-50 relative" ><span>About Us</span></Link>
                            </Magnetic>
                        </div>
                    </div>

                </motion.div>
            </div>
        </section>

    )
}

export default WhoWeAre;


