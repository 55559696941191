import { Home, About, Career, Contact, Services} from "@/pages";



export const routes = [
  {
    name: "Home",
    path: "/",
    element: <Home />,
  },
  {
    name: "About",
    path: "/about",
    element: <About />,
  },
  {
    name: "Career",
    path: "/career",
    element: <Career />,
  },
  {
    name: "Services",
    path: "/services",
    element: <Services />,
  },
  {
    name: "Contact",
    path: "/contact",
    element: <Contact />,
  },
 
  
];

export default routes;
