import { useContext } from "react";
import { motion } from "framer-motion";

import { AnimatedCursorContext } from "@/cursor/animatedcursormanager";
import useMousePosition from "@/cursor/usemouseposition";

const AnimatedCursor = () => {
  const { cursorType } = useContext(AnimatedCursorContext);
  const { x, y } = useMousePosition();
  const variants = {
    default: {
      height: 40,
      width: 40,
      x: x - 20,
      y: y - 20,

    },
    none: {
      height: 0,
      width: 0,
      x: x - 0,
      y: y - 0,

    },
    hover: {
      height: 200,
      width: 200,
      x: x - 100,
      y: y - 100,
      filter: "blur(5px)",
      mixBlendMode: "difference",
      backgroundColor: "rgba(255, 255, 255, 0.9)"
    },
    light: {
      height: 100,
      width: 100,
      x: x - 50,
      y: y - 50,
      filter: "blur(2px)",
      mixBlendMode: "difference",
    },
    blur: {
      height: 200,
      width: 200,
      x: x - 100,
      y: y - 100,
      filter: "blur(50px)",
      backgroundColor: "rgba(255, 255, 255, 0.6)",
    },
    linkHover: {
      height: 200,
      width: 200,
      x: x - 100,
      y: y - 100,
      filter: "blur(40px)",
      mixBlendMode: "exclusion",
      backgroundColor: "rgba(255, 255, 255, 0.8)",
      Color: "rgba(255, 255, 255, 1)",
    },

  };
  const transition = {
    type: "tween", // Use tween animation type
    duration: 1, // Adjust duration as needed
  };


  return (
    <motion.div
      // style={{
      //   backgroundColor: cursorType === "hover, light" ? "" : ""
      // }}
      className="cursor"
      variants={variants}
      animate={cursorType}
      transition={transition}
    />
  );
};

export default AnimatedCursor;