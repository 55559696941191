import React from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import TitleAnimation from '@/common/titleanimation';
function ServicesCard() {

  return (
    <section className='sec-padding px-2 z-0 !pb-0'>
      
      <div className='container mx-auto mt-0 lg:mt-24'>
      <div className="relative w-fit text-center mx-auto mb-10">
        <TitleAnimation text1="Our" text2="Services" /> 
      </div>
        <div className=''>
          <div className="relative group block p-2 flex items-center flex-col lg:flex-row gap-10 h-full min-h-0 lg:min-h-[60vh]"
          >
            <motion.div className="services-card w-full h-full"
           
              initial={{ scale: 1, opacity: 1, rotate: 0, y: 0, x: -600 }}
              whileInView={{
                scale: 1,
                rotate: -5,
                y: 0,
                opacity: 1,
                x: 0
              }}
              whileTap={{
                scale: 1.1,
                x: -200,
              }}

              transition={{
                duration: 1
              }}

            >
              <div className="item" 
               >
                <h3>
                  Web design
                </h3>
              </div>
              
            </motion.div>

            <motion.div className='w-full text-center'
              initial={{ scale: 0.1, opacity: 0.1, rotate: -15, }}
              whileInView={{
                scale: 1,
                rotate: 0,
                opacity: 1,

              }}
              transition={{
                duration: 2
              }}
            >
              <p className='common-fonts font-light py-0 pb-10 lg:py-10'>Where we're dedicated to being your reliable ally in shaping groundbreaking and potent web applications that propel digital evolution. Our adept team of developers merges leading-edge technologies with top industry standards to furnish scalable, safeguarded, and intuitive web solutions customized to align perfectly with your distinct business requirements.</p>
            </motion.div>
          </div>
          <div className="relative group block p-2 flex items-center flex-col lg:flex-row gap-10 h-full min-h-0 lg:min-h-[60vh] "
          >
            <motion.div className='w-full text-center order-2 lg:order-1'
              initial={{ scale: 0.1, opacity: 0.1, rotate: -15, }}
              whileInView={{
                scale: 1,
                rotate: 0,
                opacity: 1,
              }}
              transition={{
                duration: 2
              }}
            >
              <p className='common-fonts font-light py-0 pb-10 lg:py-10'>We specialize in crafting cutting-edge web applications tailored to meet the unique needs of businesses across various industries. Our expert team of developers utilizes the latest technologies and industry best practices to deliver high-quality, scalable, and secure web solutions.</p>
            </motion.div>
            <motion.div className="services-card w-full order-1 lg:order-2"

              initial={{ scale: 1, opacity: 1, rotate: 0, y: 0, x: 600 }}
              whileInView={{
                scale: 1,
                rotate: 5,
                y: 0,
                opacity: 1,
                x: 0
              }}
              whileTap={{
                scale: 1.1,
                x: 200,
              }}

              transition={{
                duration: 1
              }}
            >
              <div className="item text-center">
                <h3>Web Application Development</h3>
              </div>
            </motion.div>
          </div>
          <div className="relative group block p-2 flex items-center flex-col lg:flex-row gap-10 h-full min-h-0 lg:min-h-[60vh]"
          >
            <motion.div className="services-card w-full h-full"
              initial={{ scale: 1, opacity: 1, rotate: 0, y: 0, x: -600 }}
              whileInView={{
                scale: 1,
                rotate: -5,
                y: 0,
                opacity: 1,
                x: 0
              }}
              whileTap={{
                scale: 1.1,
                x: -200,
              }}

              transition={{
                duration: 1
              }}

            >
              <div className="item">
                <h3>
                Server Setup & Management
                </h3>
              </div>
            </motion.div>

            <motion.div className='w-full text-center'
              initial={{ scale: 0.1, opacity: 0.1, rotate: -15, }}
              whileInView={{
                scale: 1,
                rotate: 0,
                opacity: 1,
              }}
              transition={{
                duration: 2
              }}
            >
              <p className='common-fonts font-light py-0 pb-10 lg:py-10'>Your trusted partner for professional server administration and management solutions. Our dedicated team of experts specializes in ensuring the optimal performance, security, and reliability of your server infrastructure.</p>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ServicesCard
