import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

export function Video() {
  const videoRef = useRef(null);
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    gsap.fromTo(videoRef.current,
      {
        scale: 0.14,
        y: 0,
      },
      {
        y: 0,
        scaleX: 1,
        scaleY: 1,
        duration: 1,
        scrollTrigger: {
          trigger: videoRef.current,
          start: "bottom bottom",
          scrub: true,
          markers: false,
          toggleActions: 'play pause reverse complete'
        }
      });
  }, []);

  return (
    <section className='sec-padding px-2 z-1 !py-0 relative'>
      <div className="vediowrapper">
        <video ref={videoRef} loop muted playsInline autoPlay>
          <source src="./img/webcrown.mp4" type="video/mp4" />
        </video>
      </div>
      
    </section>
  );
}

export default Video;
