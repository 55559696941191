import { motion } from 'framer-motion';
import { React, useContext } from 'react';
import Paragraph from '@/description/paragraph';
import { AnimatedCursorContext } from '@/cursor/animatedcursormanager';
import AnimatedTextWord from '@/component/animatedtextword';
function Homebanner() {
    const { cursorStyleHandler } = useContext(AnimatedCursorContext);

    const textEnter = () => {
        cursorStyleHandler("hover");
    };

    const textLeave = () => {
        cursorStyleHandler("default");
    };


    return (
        <section className='wcrown-banner'>
            <div className='text-center flex flex-col items-center justify-center'>
                <div className="slide-bg">
                    <motion.div className="absolute right-0 top-0"
                        animate={{
                            scale: [1, 0.5, 1],
                            y: [180, 380, 600],
                            x: [0, -600, -200],
                        }}
                        transition={{
                            repeat: Infinity,
                            repeatType: "reverse",
                            duration: 15,
                        }}
                        initial={{
                            y: -180,
                            x: 0,
                        }}

                    >
                        <svg width="191" height="190" viewBox="0 0 191 190" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g filter="url(#filter0_f_468_2302)">
                                <ellipse cx="95.9331" cy="95" rx="64.95" ry="65" fill="url(#paint0_radial_468_2302)" />
                            </g>
                            <defs>
                                <filter id="filter0_f_468_2302" x="0.983093" y="0" width="189.9" height="190" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                    <feGaussianBlur stdDeviation="15" result="effect1_foregroundBlur_468_2302" />
                                </filter>
                                <radialGradient id="paint0_radial_468_2302" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(86.7319 20.7916) rotate(87.7383) scale(137.149 1614.43)">
                                    <stop stopColor="#554949" />
                                    <stop offset="1" stopColor="#141414" />
                                </radialGradient>
                            </defs>
                        </svg>
                    </motion.div>
                    <motion.div className="absolute right-0 top-0"

                        animate={{
                            scale: [1, 2, 1],
                            y: [200, 780, 800],
                            x: [-300, -200, -500],
                        }}
                        transition={{
                            repeat: Infinity,
                            repeatType: "reverse",
                            duration: 20,
                        }}
                        initial={{
                            y: -200,
                            x: 0,
                        }}

                    >
                        <svg width="191" height="190" viewBox="0 0 191 190" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g filter="url(#filter0_f_468_2302)">
                                <ellipse cx="95.9331" cy="95" rx="64.95" ry="65" fill="url(#paint0_radial_468_2302)" />
                            </g>
                            <defs>
                                <filter id="filter0_f_468_2302" x="0.983093" y="0" width="189.9" height="190" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                    <feGaussianBlur stdDeviation="15" result="effect1_foregroundBlur_468_2302" />
                                </filter>
                                <radialGradient id="paint0_radial_468_2302" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(86.7319 20.7916) rotate(87.7383) scale(137.149 1614.43)">
                                    <stop stopColor="#554949" />
                                    <stop offset="1" stopColor="#141414" />
                                </radialGradient>
                            </defs>
                        </svg>
                    </motion.div>
                    <motion.div className="-z-10 absolute left-0 top-0"
                        animate={{
                            scale: [1, 0.5, 1],
                            y: [300, 0, 600],
                            x: [800, 0, 800],
                        }}
                        transition={{
                            repeat: Infinity,
                            repeatType: "reverse",
                            duration: 20,
                        }}
                        initial={{
                            y: 230,
                            x: 200,
                        }}

                    >
                        <svg width="191" height="190" viewBox="0 0 191 190" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g filter="url(#filter0_f_468_2302)">
                                <ellipse cx="95.9331" cy="95" rx="64.95" ry="65" fill="url(#paint0_radial_468_2302)" />
                            </g>
                            <defs>
                                <filter id="filter0_f_468_2302" x="0.983093" y="0" width="189.9" height="190" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                    <feGaussianBlur stdDeviation="15" result="effect1_foregroundBlur_468_2302" />
                                </filter>
                                <radialGradient id="paint0_radial_468_2302" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(86.7319 20.7916) rotate(87.7383) scale(137.149 1614.43)">
                                    <stop stopColor="#554949" />
                                    <stop offset="1" stopColor="#141414" />
                                </radialGradient>
                            </defs>
                        </svg>
                    </motion.div>
                    <motion.div className="-z-10 absolute right-0 top-0"
                        animate={{
                            scale: [1, 2, 1],
                            y: [500, 20, 600],
                            x: [0, 900, 0],
                        }}
                        transition={{
                            repeat: Infinity,
                            repeatType: "reverse",
                            duration: 20,
                        }}
                        initial={{
                            y: 180,
                            x: 900,
                        }}
                    >
                        <svg width="80" height="80" viewBox="0 0 191 190" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g filter="url(#filter0_f_468_2302)">
                                <ellipse cx="95.9331" cy="95" rx="64.95" ry="65" fill="url(#paint0_radial_468_2302)" />
                            </g>
                            <defs>
                                <filter id="filter0_f_468_2302" x="0.983093" y="0" width="189.9" height="190" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                    <feGaussianBlur stdDeviation="15" result="effect1_foregroundBlur_468_2302" />
                                </filter>
                                <radialGradient id="paint0_radial_468_2302" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(86.7319 20.7916) rotate(87.7383) scale(137.149 1614.43)">
                                    <stop stopColor="#554949" />
                                    <stop offset="1" stopColor="#141414" />
                                </radialGradient>
                            </defs>
                        </svg>
                    </motion.div>
                    <motion.div className="-z-10 absolute left-0 top-0"
                        animate={{
                            scale: [1, 2, 1],
                            y: [250, 20, 900],
                            x: [300, 0, 100],
                        }}
                        transition={{
                            repeat: Infinity,
                            repeatType: "reverse",
                            duration: 20,
                        }}
                        initial={{
                            y: 400,
                            x: 1000,
                        }}
                    >
                        <svg width="120" height="120" viewBox="0 0 191 190" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g filter="url(#filter0_f_468_2302)">
                                <ellipse cx="95.9331" cy="95" rx="64.95" ry="65" fill="url(#paint0_radial_468_2302)" />
                            </g>
                            <defs>
                                <filter id="filter0_f_468_2302" x="0.983093" y="0" width="189.9" height="190" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                    <feGaussianBlur stdDeviation="15" result="effect1_foregroundBlur_468_2302" />
                                </filter>
                                <radialGradient id="paint0_radial_468_2302" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(86.7319 20.7916) rotate(87.7383) scale(137.149 1614.43)">
                                    <stop stopColor="#554949" />
                                    <stop offset="1" stopColor="#141414" />
                                </radialGradient>
                            </defs>
                        </svg>
                    </motion.div>
                    <motion.div className="-z-10 absolute right-0 top-0"
                        animate={{
                            scale: [1, 0.5, 1],
                            y: [450, 380, 600],
                            x: [450, 0, 800],
                        }}
                        transition={{
                            repeat: Infinity,
                            repeatType: "reverse",
                            duration: 20,
                        }}
                        initial={{
                            y: -200,
                            x: -450,
                        }}

                    >
                        <svg width="150" height="150" viewBox="0 0 191 190" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g filter="url(#filter0_f_468_2302)">
                                <ellipse cx="95.9331" cy="95" rx="64.95" ry="65" fill="url(#paint0_radial_468_2302)" />
                            </g>
                            <defs>
                                <filter id="filter0_f_468_2302" x="0.983093" y="0" width="189.9" height="190" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                    <feGaussianBlur stdDeviation="15" result="effect1_foregroundBlur_468_2302" />
                                </filter>
                                <radialGradient id="paint0_radial_468_2302" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(86.7319 20.7916) rotate(87.7383) scale(137.149 1614.43)">
                                    <stop stopColor="#554949" />
                                    <stop offset="1" stopColor="#141414" />
                                </radialGradient>
                            </defs>
                        </svg>
                    </motion.div>
                    <motion.div className="-z-10 absolute left-0 top-0"
                        animate={{
                            scale: [1, 0.8, 1.5],
                            y: [230, 280, 100],
                            x: [450, 0, 800],
                        }}
                        transition={{
                            repeat: Infinity,
                            repeatType: "reverse",
                            duration: 10,
                        }}
                        initial={{
                            y: 200,
                            x: 450,
                        }}
                    >
                        <svg width="100" height="100" viewBox="0 0 191 190" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g filter="url(#filter0_f_468_2302)">
                                <ellipse cx="95.9331" cy="95" rx="64.95" ry="65" fill="url(#paint0_radial_468_2302)" />
                            </g>
                            <defs>
                                <filter id="filter0_f_468_2302" x="0.983093" y="0" width="189.9" height="190" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                    <feGaussianBlur stdDeviation="15" result="effect1_foregroundBlur_468_2302" />
                                </filter>
                                <radialGradient id="paint0_radial_468_2302" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(86.7319 20.7916) rotate(87.7383) scale(137.149 1614.43)">
                                    <stop stopColor="#554949" />
                                    <stop offset="1" stopColor="#141414" />
                                </radialGradient>
                            </defs>
                        </svg>
                    </motion.div>
                </div>
                <div className='w-full lg:w-3/4 xl:w-4/5 mx-auto px-10 lg:px-0'>
                    <h1 className="title-text" onMouseEnter={textEnter} onMouseLeave={textLeave}>
                        <AnimatedTextWord text="A powerful voice echoing through the digital expanse." />
                    </h1>
                    <div className="title-con">
                        <Paragraph paragraph={"We triumphantly echo success by delivering the cutting-edge tech development to companies and startups."} />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Homebanner
