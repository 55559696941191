import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import routes from "@/routes";
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState, useRef } from 'react';
import ReactLenis from "@studio-freight/react-lenis";
import webGLFluidEnhanced from 'webgl-fluid-enhanced';
import Navbar from "./common/navbar";
import Footer from "./common/footer";
import FooterBg from "./common/footerbg";
import AnimatedCursorProvider from "./cursor/animatedcursormanager";
import AnimatedCursor from "./cursor/animatedcursor";
import Preloader from "./component/preloader";
import { PageNotFound } from "./pages";
import ScrollToTop from "./scrolltotop";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const canvasRef = useRef(null);
  const stickyElement = useRef(null);

  useEffect(() => {
    const initFluidEffect = async () => {
      // Initialize LocomotiveScroll dynamically
      const LocomotiveScrollModule = await import('locomotive-scroll');
      const LocomotiveScroll = LocomotiveScrollModule.default;
      const locomotiveScroll = new LocomotiveScroll();

      // Initialize webGLFluidEnhanced simulation
      if (canvasRef.current) {
        webGLFluidEnhanced.simulation(canvasRef.current, {
          SIM_RESOLUTION: 128, // Resolution of the simulation grid
          DYE_RESOLUTION: 1440, // Resolution of the dye grid
          CAPTURE_RESOLUTION: 512, // Resolution of captured frames
          DENSITY_DISSIPATION: 3.5, // Rate at which density dissipates
          VELOCITY_DISSIPATION: 2, // Rate at which velocity dissipates
          PRESSURE_DISSIPATION: 0.8,
          PRESSURE_ITERATIONS: 25, // Number of pressure iterations
          PRESSURE: 0.1, // Pressure value used in the simulation
          CURL: 3, // Curl value used in the simulation
          INITIAL: true, // Enables splats on initial load
          SPLAT_AMOUNT: 5, // Number of initial splats (Random number between n and n * 5)
          SPLAT_RADIUS: 0.2, // Radius of the splats
          SPLAT_FORCE: 6000, // Force applied by the splats
          SPLAT_KEY: 'Space', // Keyboard key to spawn new splats (empty to disable)
          SHADING: true, // Enables shading in the visualization
          COLORFUL: true, // Enables rapid changing of colors
          COLOR_UPDATE_SPEED: 50, // Speed of color update
          COLOR_PALETTE: ['#554949', '#fff0f0', '#F80505', '#fff0f0', '#554949'],
          HOVER: true, // Enables interaction on hover
          BACK_COLOR: '#0000', // Background color of the canvas
          TRANSPARENT: false, // Makes the canvas transparent if true
          BRIGHTNESS: 0.5, // Color brightness (Recommend lower than 1.0 if BLOOM is true)
          BLOOM: false, // Enables bloom effect
          BLOOM_ITERATIONS: 8, // Number of bloom effect iterations
          BLOOM_RESOLUTION: 256, // Resolution of the bloom effect
          BLOOM_INTENSITY: 0.8, // Intensity of the bloom effect
          BLOOM_THRESHOLD: 0.6, // Threshold for the bloom effect
          BLOOM_SOFT_KNEE: 0.7, // Soft knee value for the bloom effect
          SUNRAYS: false, // Enables sunrays effect
          SUNRAYS_RESOLUTION: 196, // Resolution of the sunrays effect
          SUNRAYS_WEIGHT: 1.0, // Weight of the sunrays effect
        });
      }

      // Set loading state and adjust cursor
      setTimeout(() => {
        setIsLoading(false);
        document.body.style.cursor = 'default';
        window.scrollTo(0, 0);
      }, 2000);
    };

    initFluidEffect();
  }, []);

  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
  }, []);
  const location = useLocation();
  const pathName = location.pathname;
  const isContactsPage = pathName === '/contact';
  return (
    <>
      <AnimatePresence mode='wait'>
        {isLoading && <Preloader />}
      </AnimatePresence>
      <AnimatePresence mode="wait">
        <div key={pathName} className="">
          <motion.div
            className="h-screen w-screen fixed bg-black rounded-b-[200px] z-40 top-0"
            animate={{ height: "0vh" }}
            exit={{ height: "140vh" }}
            transition={{ duration: 0.5, ease: "easeOut" }}
          />
          <motion.div
            className="fixed m-auto top-0 bottom-0 left-0 right-0 text-red-600 text-8xl cursor-default z-50 w-fit h-fit uppercase"
            initial={{ opacity: 1, zIndex: "50", }}
            animate={{ opacity: 0, zIndex: "0" }}
            exit={{ opacity: 0, zIndex: "0" }}
            transition={{ duration: 0.8, ease: "easeOut" }}
          >
            {pathName.substring(1)}
          </motion.div>
          <motion.div
            className="h-screen w-screen fixed bg-black rounded-t-[200px] bottom-0 z-30"
            initial={{ height: "140vh" }}
            animate={{ height: "0vh", transition: { delay: 0.5 } }}
          />


          <AnimatedCursorProvider>
            <AnimatedCursor />
            <Navbar routes={routes} />

            <div className="hide-scrollbar-div">
              <ReactLenis className="relative z-1 " root options={{
                lerp: 0.05,
                duration: 1,
                smoothTouch: true
              }}>
                <ScrollToTop />
                <Routes>
                  {routes.map(
                    ({ path, element }, key) =>
                      element && <Route key={key} exact path={path} element={element} />
                  )}
                  <Route path="/" element={<Navigate to="/home" replace />} />
                  <Route path="*" element={<PageNotFound />} />
                </Routes>
                {!isContactsPage && <Footer />}
                <FooterBg />
              </ReactLenis>
            </div>

          </AnimatedCursorProvider>
        </div>
      </AnimatePresence>
      <canvas ref={canvasRef} className="fixed top-0 left-0 w-screen h-screen mix-blend-lighten z-0"></canvas>
    </>
  );
}

export default App;
