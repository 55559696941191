import { useRef } from 'react';
import { motion, useTransform, useScroll } from 'framer-motion';

const ServicePageCard = ({ title, details, items }) => {
  const titleRef = useRef(null);
  const detailsRef = useRef(null);

  const { scrollYProgress: titleScrollYProgress } = useScroll({
    target: titleRef,
    offset: ["start 0.9", "end 0.9"]
  });
  const { scrollYProgress: detailsScrollYProgress } = useScroll({
    target: detailsRef,
    offset: ["start 0.9", "end 0.9"]
  });

  const translateXtitle = useTransform(titleScrollYProgress, [0, 1], ['-100%', '0%']);
  const translateYtitle = useTransform(titleScrollYProgress, [0, 1], ['100%', '0%']);
  const translateXdetail = useTransform(detailsScrollYProgress, [0, 1], ['100%', '0%']);
  const translateYdetail = useTransform(detailsScrollYProgress, [0, 1], ['100%', '0%']);

  return (
    <div className="relative h-full">
      <motion.div className="services-card-title w-full"
        ref={titleRef}
        style={{
          transformOrigin: 'left',
          translateX: translateXtitle,
          translateY: translateYtitle
        }}
      >
        <div className="item">
          <h3>{title}</h3>
        </div>
      </motion.div>

      <motion.div className='services-card-details w-full text-center'
        ref={detailsRef}
        style={{
          transformOrigin: 'right',
          translateX: translateXdetail,
          translateY: translateYdetail,
        }}
      >
        <div className="item">
          <div>
            <h5 className='!text-red-500'>{details}</h5>
            <ul>
              {items.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
          </ul>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default ServicePageCard;
