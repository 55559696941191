import { AnimatedCursorContext } from '@/cursor';
import React, { useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
export function PageNotFound() {
    const background = useRef(null);

    const setBackground = (isActive) => {
        gsap.to(background.current, { opacity: isActive ? 0.8 : 0 })
    }
    const { cursorStyleHandler } = useContext(AnimatedCursorContext);
    const textEnter = () => {
        cursorStyleHandler("linkHover");
    };
    const textLeave = () => {
        cursorStyleHandler("default");
    };
    return (
        <>
            <section className="bg-primary-50/50 w-full py-10 lg:py-20 relative min-h-svh flex items-center">
                <div className="container">
                    <div className="text-center">
                        <h2 className='mt-4 mb-5 text-gray-600 text-[4vw] font-bold'>404</h2>
                        <h3 className='mt-4 mb-5 text-gray-600 text-[8vw]'>Page not found</h3>
                        <p className="mt-4 mb-5 text-gray-600 text-2xl">Sorry, we couldn’t find the page you’re looking for.</p>
                        <div className="mx-auto md:ml-auto md:mr-0 relative z-1 w-auto flex justify-center">
                            <Link to="/" className="btn-white w-auto"
                                onMouseEnter={textEnter} onMouseLeave={textLeave}
                            >Back To Home</Link>
                        </div>

                    </div>
                </div>
            </section >

        </>
    )
}

export default PageNotFound;