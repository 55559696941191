import React, { useRef, useEffect } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

const animateText = (textElement, start, end) => {
  gsap.to(textElement, {
    backgroundSize: '100%',
    ease: 'none',
    scrollTrigger: {
      trigger: textElement,
      start,
      end,
      scrub: 1,
      markers: false,
    },
  });
};

const TitleAnimation = ({ text1, text2 }) => {
  const titleView = useRef(null);
  const { scrollYProgress: titleScrollYProgress } = useScroll({
    target: titleView,
    offset: ["start 0.1 ", "end 0.9 "]
  });

  const titleTranslateX = useTransform(titleScrollYProgress, [0, 1], ["100px", "-50px"]);
  const titleTranslateXUp = useTransform(titleScrollYProgress, [0, 1], ["100px", "-50px"]);
  const titleTranslateDo = useTransform(titleScrollYProgress, [0, 1], ["-100px", "50px"]);
  const titleTranslateDoUp = useTransform(titleScrollYProgress, [0, 1], ["-100px", "50px"]);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const textElements = document.querySelectorAll('.text');
    textElements.forEach((text) => {
      animateText(text, 'center 95%', 'center 45%');
    });
  }, []);

  return (
    <div className="sec-title relative" ref={titleView}>
      <motion.h2
        initial={{
          x: 100,
        }}
        whileInView={{
          x: 0,
        }}
        transition={{
          ease: "linear",
          duration: 2,
          x: { duration: 1 }
        }}
      >
        <motion.span className='block text'
          style={{
            translateX: titleScrollYProgress.get() > 0 ? titleTranslateXUp : titleTranslateX,
          }}>{text1}</motion.span>
        <motion.span className='block text'
          style={{
            translateX: titleScrollYProgress.get() > 0 ? titleTranslateDoUp : titleTranslateDo,
          }}
        >{text2}</motion.span>
      </motion.h2>
    </div>
  );
};

export default TitleAnimation;
