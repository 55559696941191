import SubBanner from "@/common/subbanner";
import { motion } from "framer-motion";
import { useRef, React, useContext, useEffect, useState } from "react";
import { AnimatedCursorContext } from "@/cursor/animatedcursormanager";
import TitleAnimation from "@/common/titleanimation";
import WordText from "@/description/word";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ScrollingGallery from "@/component/scrollinggallery";

export function About() {

  useEffect(() => {
    const mask = document.querySelector('.maskimg');
    const onMouseMove = (pos) => {
      let x = parseInt(pos.clientX / window.innerWidth * 100);
      let y = parseInt(pos.clientY / window.innerHeight * 100);

      mask.style.setProperty('--mouse-x', x + '%');
      mask.style.setProperty('--mouse-y', y + '%');
    };

    document.addEventListener('pointermove', onMouseMove);

    return () => {
      document.removeEventListener('pointermove', onMouseMove);
    };
  }, []);
 

  const { cursorStyleHandler } = useContext(AnimatedCursorContext);
  const imgEnter = () => {
    cursorStyleHandler("none");
  };
  const imgLeave = () => {
    cursorStyleHandler("default");
  };
  const [currentURL, setCurrentURL] = useState(window.location.href);
  useEffect(() => {
    setCurrentURL(window.location.href);
  },
    []);

  return (
    <>

      <HelmetProvider>
        <Helmet>
          <title>About Us</title>
          <meta name="title" content="About Us - Web Crown" />
          <meta name="og:url" content={currentURL} />
          <meta name="og:image" content="./img/logo.svg" />
        </Helmet>
      </HelmetProvider>

      <motion.div
        className="h-full relative z-0"
        initial={{ y: "-200vh" }}
        animate={{ y: "0%" }}
        transition={{ duration: 1 }}
      >
        <SubBanner
          title="Adaptive Development Agency"
          content="Web Crown is a leading IT solutions provider dedicated to revolutionizing global business practices. Combining business acumen with technical expertise, we deliver unparalleled value through innovation and cost-saving strategies. Committed to empowering businesses and changing lives, we strive to make a positive impact on the world, one client at a time."
        />
        <section className="sec-padding px-0 !pt-0 relative">
          <div className="mx-auto relative h-full">
            <motion.div className="absolute inset-0 flex items-center container z-10">
              <p className="text-[5vw] sm:text-[5vw] md:text-[4vw] text-center ">
                With a legacy of over twenty years,<span className="text-red-500"> WEBCROWN® </span> continues to empower businesses within the corporate realm.
              </p>
            </motion.div>

            <div className="absolute left-0 right-0">
              <div className="opacity-20">
                <img src="./img/about_mask_img.webp" alt="" className="w-full" />
              </div>
            </div>
            <div className="maskimg" onMouseEnter={imgEnter} onMouseLeave={imgLeave}>
              <div className="">
                <img src="./img/about_mask_img_hover.webp" alt="" className="w-full" />
              </div>
            </div>
          </div>
        </section>
        <section className='sec-padding px-0 !pb-0 relative' >
          <div className="relative text-center w-fit mx-auto">
            <TitleAnimation text1="Who" text2="We Are" />
          </div>
          <div className="w-full flex mt-10 relative z-10 container mx-auto">
            <motion.div className="w-full lg:w-1/2 mt-0 lg:mt-20"
              initial={{ scale: 1, opacity: 1, rotate: 0, y: -300, x: 0 }}
              whileInView={{
                scale: 1,
                rotate: 0,
                y: 0,
                opacity: 1,
                x: 0
              }}
              transition={{
                duration: 2
              }}
            >
              <div className="pr-6 text-center common-fonts">
                <WordText paragraph="Web Crown is a leading IT solutions provider dedicated to revolutionizing global business practices. By combining business acumen with technical expertise, we deliver unparalleled value through innovation and cost-saving strategies. Committed to empowering businesses and transforming lives, we strive to make a positive impact on the world, one client at a time." />
              </div>

            </motion.div>
          </div>
          <div className="w-full flex relative z-10 container mx-auto">
            <motion.div className="w-full lg:w-1/2 mt-10 lg:mt-20 ms-auto"
              initial={{ scale: 1, opacity: 1, rotate: 0, y: -300, x: 0 }}
              whileInView={{
                scale: 1,
                rotate: 0,
                y: 0,
                opacity: 1,
                x: 0
              }}
              transition={{
                duration: 2
              }}
            >
              <div className="pr-6 text-center common-fonts">
                <WordText paragraph="Web Crown is a premier IT solutions provider dedicated to transforming global business practices. By integrating business insight with technical prowess, we offer exceptional value through innovative and cost-effective strategies. Our mission is to empower businesses and improve lives, striving to make a positive impact worldwide, one client at a time." />
              </div>

            </motion.div>
          </div>
        </section>
        <section className="sec-padding !pb-0">
          <div className="relative text-center w-fit mx-auto">
            <TitleAnimation text1="Our" text2="Digital" />
          </div>
          <div className="sec-padding rotate-1">
            <ScrollingGallery />
          </div>
          
          <div className="clear-both"></div>
        </section>

      </motion.div>
    </>
  );
};

export default About;
