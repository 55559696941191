import React from 'react';
import { motion, useTransform, useScroll } from 'framer-motion';

const ProcessCard = ({ index, title, description, refProp }) => {
  const { scrollYProgress } = useScroll({
    target: refProp,
    offset: ['start 0.8', 'start start']
  });

  const scalecard = useTransform(scrollYProgress, [0, 1], [1, 0.6]);
  const borderRadius = useTransform(scrollYProgress, [0, 1], ['5px', '100%']);
  const scalecount = useTransform(scrollYProgress, [0, 1], [1, 2]);

  return (
    <motion.div className={`our-process-card ${index % 2 === 0 ? '' : 'right'}`}
      ref={refProp}
      style={{ scale: scalecard, transformOrigin: index % 2 === 0 ? 'left' : 'right' }}
    >
      <motion.div className="count-box"
        style={{
          borderRadius: borderRadius,
          scale: scalecount,
        }}
      >{index < 9 ? `0${index + 1}` : index + 1}
      </motion.div>
      <motion.div className="process-data">
        <h3>{title}</h3>
        <ul>
          {description.map((item, i) => (
            <li key={i}>{item}</li>
          ))}
        </ul>
      </motion.div>
    </motion.div>
  );
};

export default ProcessCard;